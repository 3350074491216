import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import ErrorMessage from '../../error-message/error-message';
import { Trans, useTranslation } from 'react-i18next';

export type DeleteConfirmationModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  keyValue?: string;
  releaseName?: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: unknown;
};

const DeleteConfirmationModal = ({
  isOpen,
  handleCancel,
  handleConfirm,
  keyValue,
  releaseName,
  isLoading,
  isError,
  error,
}: DeleteConfirmationModalProps) => {
  const joyUiTheme = useTheme();
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <ModalDialog minWidth={'500px'}>
        <DialogTitle level='h3'>{t('keys-table.confirm-delete.title')}</DialogTitle>
        <DialogContent>
          <div
            style={{
              margin: '16px 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('keys-table.confirm-delete.confirmation')}{' '}
              <div style={{ display: 'flex', margin: '0 8px' }}>
                <Typography fontFamily='monospace' variant='soft'>
                  {keyValue}
                </Typography>
              </div>
              {releaseName ? <span>{t('keys-table.confirm-delete.from')}{' '}{releaseName}?</span> : '?'}
            </div>
            {releaseName && (
              <>
                <div>
                  {t('keys-table.confirm-delete.prev-rel')}
                </div>
                <div
                  style={{
                    color: joyUiTheme.colorSchemes.light.palette.danger[500],
                  }}
                >
                  <Trans i18nKey='common.cannot-undo' components={{ b: <b /> }} />
                </div>
              </>
            )}

            <ErrorMessage
              isError={isError ?? false}
              error={error}
              retry={handleConfirm}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}
          >
            <Button
              startDecorator={<FontAwesomeIcon icon={faXmark} />}
              onClick={handleCancel}
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <Button
              color='danger'
              startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={handleConfirm}
              loading={isLoading}
            >
              {t('common.confirm')}
            </Button>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteConfirmationModal;
