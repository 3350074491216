import { StyleSheet } from "aphrodite";
import theme from "../../styles/theme";
import joyUiTheme from "../../styles/joyui-theme";

const fileUploadStyles = StyleSheet.create({
    dropzone: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        borderWidth: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        height: '50px'
    },
    dropzoneBase: {
        borderStyle: 'dashed',
        borderColor: theme.colour.lightGrey,
        color: theme.colour.grey,
    },
    dropzoneAccept: {
        borderStyle: 'solid',
        borderColor: joyUiTheme.colorSchemes.light.palette.primary[500],
        backgroundColor: joyUiTheme.colorSchemes.light.palette.primary[100],
        color: joyUiTheme.colorSchemes.light.palette.primary[500],
    },
    dropzoneReject: {
        borderStyle: 'solid',
        borderColor: joyUiTheme.colorSchemes.light.palette.danger[600],
        backgroundColor: joyUiTheme.colorSchemes.light.palette.danger[100],
        color: joyUiTheme.colorSchemes.light.palette.danger[600],

    },
    caption: {
        fontWeight: 600,
    },
    subcaption: {
        fontSize: '14px'
    }
});

export default fileUploadStyles;