import {
  faBan,
  faFloppyDisk,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  useTheme,
} from '@mui/joy';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type UnsavedChangesWarningModalProps = {
  isOpen: boolean;
  isLoading?: boolean;
  handleCloseWithoutSaving: () => void;
  handleStayHere: () => void;
  handleSaveAndClose: () => void;
};

const UnsavedChangesWarningModal = ({
  isOpen,
  isLoading,
  handleCloseWithoutSaving,
  handleSaveAndClose,
  handleStayHere,
}: UnsavedChangesWarningModalProps) => {
  const joyUiTheme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={handleStayHere}>
      <ModalDialog maxWidth={'700px'}>
        <DialogTitle>{t('unsaved-changes-warning.title')}</DialogTitle>
        {!isLoading && <ModalClose />}
        <DialogContent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <div>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  color={joyUiTheme.colorSchemes.light.palette.danger[500]}
                  size='2x'
                />
              </div>
              <div>
                <Trans
                  i18nKey='unsaved-changes-warning.body'
                  components={{ b: <b /> }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <Button
                color='neutral'
                startDecorator={<FontAwesomeIcon icon={faBan} />}
                onClick={handleStayHere}
                disabled={isLoading}
              >
                {t('unsaved-changes-warning.stay')}
              </Button>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                  color='danger'
                  startDecorator={<FontAwesomeIcon icon={faXmark} />}
                  onClick={handleCloseWithoutSaving}
                  disabled={isLoading}
                >
                  {t('unsaved-changes-warning.close')}
                </Button>
                <Button
                  startDecorator={<FontAwesomeIcon icon={faFloppyDisk} />}
                  onClick={handleSaveAndClose}
                  loading={isLoading}
                >
                  {t('unsaved-changes-warning.save')}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default UnsavedChangesWarningModal;
