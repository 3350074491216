import React from 'react';
import { css } from 'aphrodite';
import navigationBarStyles from './navigation-bar.styles';
import { Link } from 'react-router-dom';
import { Page } from '../../../types/navigation';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commonStyles from '../../../styles/common.styles';
import { getEnvVariables } from '../../../util/env-variables';
import useAuth from '../../../util/auth-hook';
import { useTranslation } from 'react-i18next';

const NavigationBar = () => {
  const { user, logout } = useAuth();
  const { rootUrl } = getEnvVariables();
  const { t } = useTranslation();
  return (
    <div className={css(navigationBarStyles.barContainer)}>
      <div style={{ height: '100%' }}>
        <Link to={Page.Dashboard}>
          <img
            src='/img/OneTranslateLogo.svg'
            height={'100%'}
            alt={`OneTranslate ${t('nav.logo')}`}
          />
        </Link>
      </div>
      <div className={css(navigationBarStyles.profileContainer)}>
        <div className={css(navigationBarStyles.imageContainer)}>
          <img
            src={user?.picture}
            className={css(navigationBarStyles.roundedImage)}
            height={'100%'}
            alt={user?.name}
          />
        </div>
        <div className={css(navigationBarStyles.userName)}>{user?.name}</div>
        <div className={css(navigationBarStyles.divider)} />
        <button
          className={css(
            commonStyles.nativeReset,
            navigationBarStyles.signOutContainer,
          )}
          onClick={() => logout({
            logoutParams: {
              returnTo: rootUrl
            }
          })}
        >
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          <div>{t('nav.sign-out')}</div>
        </button>
      </div>
    </div>
  );
};

export default NavigationBar;
