import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Link,
  Skeleton,
  Table,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import React from 'react';
import {
  TranslationKeyData,
  TranslationKeyInput,
} from '../../types/translation';
import Card from '../card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faFolderPlus,
  faPencil,
  faPlus,
  faTrashCan,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { css } from 'aphrodite';
import keysTableStyles from './keys-table.styles';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import translationService from '../../service/translation';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import LoadingMessage from '../loading-message/loading-message';
import DeleteConfirmationModal from './delete-confirmation-modal/delete-confirmation-modal';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SavingStatus } from '../saving-indicator/saving-indicator';
import useAuth from '../../util/auth-hook';
import userService from '../../service/user';
import generalUtil from '../../util/general';
import authUtil from '../../util/auth';
import ImportKeyModal from '../import-key-modal/import-key-modal';
import { useTranslation } from 'react-i18next';

export type KeysTableProps = {
  releaseId?: number;
  releaseName?: string;
  showReview?: boolean;
  setSavingStatus?: (status: SavingStatus) => void;
  updateKeyCount?: (count: number) => void;
  updateAllReviewedStatus?: (allReviewed: boolean) => void;
  openKeyFormatModal?: () => void;
  isLoading?: boolean;
  editable: boolean;
};

type UpdateFormValues = {
  primaryValue: string;
  placeholder: boolean;
  context?: string;
};

type AddFormValues = {
  key: string;
  primaryValue: string;
  placeholder: boolean;
  context?: string;
};

type ReviewKeyInput = {
  reviewed: boolean;
  key?: string;
  keyIndex?: number;
};

const KeysTable = ({
  releaseId,
  releaseName,
  showReview,
  setSavingStatus,
  updateKeyCount,
  updateAllReviewedStatus,
  openKeyFormatModal,
  isLoading,
  editable,
}: KeysTableProps) => {
  const [rowEditing, setRowEditing] = React.useState<number | undefined>();
  const [deleteCandidate, setDeleteCandidate] = React.useState<
    string | undefined
  >();
  const [addingKey, setAddingKey] = React.useState(false);
  const [allReviewed, setAllReviewed] = React.useState<boolean>(false);
  // number = key index | null = all reviewing | undefined = none reviewing
  const [keyReviewing, setKeyReviewing] = React.useState<
    number | null | undefined
  >(undefined);
  const [isBulkImportOpen, setIsBulkImportOpen] = React.useState(false);

  const joyUiTheme = useTheme();

  const { getAccessTokenSilently, user } = useAuth();

  const { t, i18n } = useTranslation();

  const {
    data: existingTranslationKeys,
    isFetching: isExistingTranslationKeysFetching,
    isError: isExistingTranslationKeysError,
    error: existingTranslationKeysError,
    refetch: refetchExistingTranslationKeys,
  } = useQuery(
    ['existing-translation-keys', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getTranslationKeys(
        accessToken,
        Number(releaseId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onSuccess: (data) => {
        setRows(data);
      },
    },
  );

  const {
    data: releaseParticipantsUserData,
    isFetching: isReleaseParticipantsFetching,
    isError: isReleaseParticipantsError,
    error: releaseParticipantsError,
    refetch: refetchReleaseParticipants,
  } = useQuery(
    ['release-participants', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const res = await userService.getReleaseParticipants(
        accessToken,
        Number(releaseId),
      );

      return user ? [...res, authUtil.getCurrentUserObject(user)] : res;
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    isLoading: isUpdateRowLoading,
    isError: isUpdateRowError,
    error: updateRowError,
    mutate: submitUpdateRow,
    reset: resetUpdateRow,
  } = useMutation(
    async (data: TranslationKeyInput) => {
      const accessToken = await getAccessTokenSilently();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.updateTranslationKeys(
        accessToken,
        Number(releaseId),
        [data],
      );
    },
    {
      onSuccess: (data) => {
        setRows(data);
        setRowEditing(undefined);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const {
    isLoading: isDeleteRowLoading,
    isError: isDeleteRowError,
    error: deleteRowError,
    mutate: submitDeleteRow,
    reset: resetDeleteRow,
  } = useMutation(
    async (key: string) => {
      const accessToken = await getAccessTokenSilently();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.deleteTranslationKeys(
        accessToken,
        Number(releaseId),
        [key],
      );
    },
    {
      onSuccess: (data) => {
        setDeleteCandidate(undefined);
        setRows(data);
        setRowEditing(undefined);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const {
    isLoading: isAddKeyLoading,
    isError: isAddKeyError,
    error: addKeyError,
    mutate: submitAddKey,
    reset: resetAddKey,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();
      const values = getValuesFromAddForm();

      if (setSavingStatus) {
        setSavingStatus('saving');
      }

      return await translationService.addTranslationKeys(
        accessToken,
        Number(releaseId),
        [{ ...values, context: values.context || undefined }],
      );
    },
    {
      onSuccess: (data) => {
        setRows(data);
        addFormReset();
        setAddingKey(false);
        if (setSavingStatus) {
          setSavingStatus('saved');
        }
      },
      onError: () => {
        if (setSavingStatus) {
          setSavingStatus('failed');
        }
      },
    },
  );

  const {
    isLoading: isReviewKeyLoading,
    isError: isReviewKeyError,
    error: reviewKeyError,
    mutate: reviewKey,
  } = useMutation(
    async (input: ReviewKeyInput) => {
      const accessToken = await getAccessTokenSilently();

      setKeyReviewing(input.keyIndex ?? null);

      return await translationService.reviewTranslation(
        accessToken,
        Number(releaseId),
        input.reviewed,
        undefined,
        input.key,
      );
    },
    {
      onError: () => {
        setKeyReviewing(undefined);
      },
      onSuccess: (_response, input) => {
        setRows((existingRows) => {
          return existingRows.map((exstData) => {
            if (!input.key || exstData.key === input.key) {
              return {
                ...exstData,
                review: input.reviewed
                  ? {
                      user: user?.sub ?? 'unknown',
                      timestamp: new Date().toISOString(),
                    }
                  : undefined,
              };
            } else {
              return exstData;
            }
          });
        });

        if (!input.key) {
          setAllReviewed(input.reviewed);
        }

        setKeyReviewing(undefined);
      },
    },
  );

  const [rows, setRows] = React.useState<TranslationKeyData[]>(
    existingTranslationKeys ?? [],
  );

  const keysInTable = React.useMemo(() => rows.map((row) => row.key), [rows]);

  const updateFormValidationSchema = Yup.object().shape({
    primaryValue: Yup.string()
      .required(t('keys-table.errors.primary-value.required'))
      .max(
        2000,
        t('keys-table.errors.primary-value.length', { maxLength: 2000 }),
      ),
    placeholder: Yup.boolean().required(),
    context: Yup.string().max(
      2000,
      t('keys-table.errors.context.length', { maxLength: 2000 }),
    ),
  });

  const addFormValidationSchema = Yup.object().shape({
    key: Yup.string()
      .required(t('keys-table.errors.key.required'))
      .max(255, t('keys-table.errors.key.length', { maxLength: 255 }))
      .matches(generalUtil.KEY_REGEX, t('keys-table.errors.key.invalid'))
      .notOneOf(keysInTable, t('keys-table.errors.key.duplicate')),
    primaryValue: Yup.string()
      .required(t('keys-table.errors.primary-value.required'))
      .max(
        2000,
        t('keys-table.errors.primary-value.length', { maxLength: 2000 }),
      ),
    placeholder: Yup.boolean().required(),
    context: Yup.string().max(
      2000,
      t('keys-table.errors.context.length', { maxLength: 2000 }),
    ),
  });

  const {
    control: updateFormControl,
    register: updateFormRegister,
    reset: updateFormReset,
    getValues: getValuesFromUpdateForm,
    formState: { isValid: isUpdateFormValid, errors: updateFormErrors },
  } = useForm<UpdateFormValues>({
    resolver: yupResolver(updateFormValidationSchema),
    mode: 'all',
  });

  const {
    control: addFormControl,
    register: addFormRegister,
    reset: addFormReset,
    getValues: getValuesFromAddForm,
    formState: { isValid: isAddFormValid, errors: addFormErrors },
  } = useForm<AddFormValues>({
    resolver: yupResolver(addFormValidationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    if (updateKeyCount) {
      updateKeyCount(rows.length);
    }
  }, [rows, updateKeyCount]);

  React.useEffect(() => {
    setAllReviewed(
      rows.length > 0 && rows?.every((keyData) => !!keyData.review),
    );
  }, [rows]);

  React.useEffect(() => {
    if (updateAllReviewedStatus) {
      updateAllReviewedStatus(allReviewed);
    }
  }, [allReviewed, updateAllReviewedStatus]);

  const editRow = (index?: number) => {
    updateFormReset();
    setRowEditing(index);
  };

  const updateRow = (data: TranslationKeyData) => {
    const values = getValuesFromUpdateForm();
    submitUpdateRow({
      ...values,
      key: data.key,
      context: values.context || undefined,
    });
  };

  const deleteRow = () => {
    submitDeleteRow(deleteCandidate as string);
  };

  const handleImportModalClose = (keysAdded?: boolean) => {
    setIsBulkImportOpen(false);
    if (keysAdded) {
      refetchExistingTranslationKeys();
    }
  };

  const showLoaders = React.useMemo(() => {
    return (
      isLoading ||
      isExistingTranslationKeysFetching ||
      isReleaseParticipantsFetching
    );
  }, [
    isLoading,
    isExistingTranslationKeysFetching,
    isReleaseParticipantsFetching,
  ]);

  return (
    <>
      <DeleteConfirmationModal
        isOpen={!!deleteCandidate}
        keyValue={deleteCandidate}
        releaseName={releaseName}
        isLoading={isDeleteRowLoading}
        isError={isDeleteRowError}
        error={deleteRowError}
        handleCancel={() => {
          setDeleteCandidate(undefined);
          resetDeleteRow();
        }}
        handleConfirm={deleteRow}
      />
      <ImportKeyModal
        isOpen={isBulkImportOpen}
        onClose={handleImportModalClose}
        releaseId={Number(releaseId)}
        existingKeys={rows}
      />
      <LoadingMessage isLoading={isExistingTranslationKeysFetching} />
      <LoadingMessage isLoading={isReleaseParticipantsFetching} />
      <ErrorMessagePopup
        isError={isExistingTranslationKeysError}
        error={existingTranslationKeysError}
        retry={refetchExistingTranslationKeys}
      />
      <ErrorMessagePopup
        isError={isReleaseParticipantsError}
        error={releaseParticipantsError}
        retry={refetchReleaseParticipants}
      />
      <ErrorMessagePopup isError={isAddKeyError} error={addKeyError} />
      <ErrorMessagePopup isError={isUpdateRowError} error={updateRowError} />
      <ErrorMessagePopup isError={isReviewKeyError} error={reviewKeyError} />
      <Card>
        <Table>
          <thead>
            <tr data-testid='key-table-heading'>
              {showReview && (
                <th
                  className={css(keysTableStyles.heading)}
                  style={{ width: '3%' }}
                >
                  <Tooltip
                    title={
                      allReviewed
                        ? t('keys-table.mark-all-unreviewed')
                        : t('keys-table.mark-all-reviewed')
                    }
                    placement='top'
                    arrow
                    enterDelay={500}
                  >
                    {isReviewKeyLoading && keyReviewing === null ? (
                      <CircularProgress
                        size='sm'
                        sx={{ '--CircularProgress-size': '20px' }}
                      />
                    ) : (
                      <Checkbox
                        checked={allReviewed}
                        onChange={(event) => {
                          reviewKey({ reviewed: event.target.checked });
                        }}
                      />
                    )}
                  </Tooltip>
                </th>
              )}
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '17%' }}
              >
                {t('keys-table.headings.key')}
              </th>
              <th className={css(keysTableStyles.heading)}>
                {t('keys-table.headings.primary-value')}
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '10%' }}
              >
                {t('keys-table.headings.placeholder')}
              </th>
              <th className={css(keysTableStyles.heading)}>
                {t('keys-table.headings.context')}
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '8%' }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {showLoaders ? (
              <tr>
                <td colSpan={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                  </div>
                </td>
              </tr>
            ) : (
              rows?.map((data, index) => (
                <tr
                  key={`${data.translationId}-${data.key}`}
                  data-testid={`key-table-row-${data.translationId}`}
                >
                  {showReview && (
                    <td
                      data-testid={`key-table-row-${data.translationId}-review`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip
                          title={
                            <>
                              {data.review ? (
                                <div style={{ textAlign: 'center' }}>
                                  <div>{t('keys-table.mark-unreviewed')}</div>
                                  <div>
                                    <i>
                                      {t('keys-table.reviewed-by')}{' '}
                                      {
                                        releaseParticipantsUserData?.find(
                                          (usr) => usr.id === data.review?.user,
                                        )?.name.full
                                      }{' '}
                                      {generalUtil.getTimestampRelativeToNow(
                                        data.review.timestamp,
                                        i18n.language,
                                      )}
                                    </i>
                                  </div>
                                </div>
                              ) : (
                                <div>{t('keys-table.mark-reviewed')}</div>
                              )}
                            </>
                          }
                          placement='top'
                          arrow
                          enterDelay={500}
                        >
                          {isReviewKeyLoading && keyReviewing === index ? (
                            <CircularProgress
                              size='sm'
                              sx={{ '--CircularProgress-size': '20px' }}
                            />
                          ) : (
                            <Checkbox
                              checked={!!data.review}
                              defaultChecked={!!data.review}
                              disabled={isReviewKeyLoading}
                              onChange={(e) => {
                                reviewKey({
                                  reviewed: e.target.checked,
                                  key: data.key,
                                  keyIndex: index,
                                });
                              }}
                            />
                          )}
                        </Tooltip>
                      </div>
                    </td>
                  )}
                  <td>
                    <div style={{ display: 'flex' }}>
                      <Typography
                        fontFamily={'monospace'}
                        variant='soft'
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        {data.key}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    {rowEditing === index ? (
                      <FormControl>
                        <Input
                          {...updateFormRegister('primaryValue')}
                          placeholder={`${t('keys-table.headings.primary-value')}...`}
                          defaultValue={data.primaryValue}
                          error={!!updateFormErrors.primaryValue}
                        />
                        {!!updateFormErrors.primaryValue && (
                          <FormHelperText
                            sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                          >
                            {updateFormErrors.primaryValue.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <div style={{ overflowWrap: 'anywhere' }}>
                        {data.primaryValue}
                      </div>
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {rowEditing === index ? (
                        <Controller
                          name='placeholder'
                          control={updateFormControl}
                          defaultValue={data.placeholder}
                          render={({ field: props }) => (
                            <Checkbox
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      ) : (
                        <Checkbox checked={data.placeholder} disabled />
                      )}
                    </div>
                  </td>
                  <td>
                    {rowEditing === index ? (
                      <FormControl>
                        <Input
                          {...updateFormRegister('context')}
                          placeholder={`${t('keys-table.headings.context')}...`}
                          defaultValue={data.context}
                          error={!!updateFormErrors.context}
                        />
                        {!!updateFormErrors.context && (
                          <FormHelperText
                            sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                          >
                            {updateFormErrors.context.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <div style={{ overflowWrap: 'anywhere' }}>
                        {data.context}
                      </div>
                    )}
                  </td>
                  <td>
                    {editable && (
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {rowEditing !== index ? (
                          <>
                            <IconButton
                              color='primary'
                              variant='solid'
                              aria-label={t('common.edit')}
                              onClick={() => editRow(index)}
                              disabled={rowEditing !== undefined || addingKey}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label={t('common.delete')}
                              disabled={rowEditing !== undefined || addingKey}
                              onClick={() => setDeleteCandidate(data.key)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              color='success'
                              variant='solid'
                              aria-label={t('common.confirm')}
                              onClick={() => updateRow(data)}
                              loading={isUpdateRowLoading}
                              disabled={!isUpdateFormValid}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label={t('common.cancel')}
                              onClick={() => {
                                editRow(undefined);
                                resetUpdateRow();
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </IconButton>
                          </>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
            {(editable || rows.length === 0) && (
              <tr data-testid='key-table-row-new'>
                {!showLoaders && (
                  <>
                    {addingKey ? (
                      <>
                        {showReview && <td />}
                        <td>
                          <Controller
                            name='key'
                            control={addFormControl}
                            render={({ field: props }) => (
                              <Input
                                error={!!addFormErrors.key}
                                placeholder={`${t('keys-table.headings.key')}...`}
                                onChange={(e) => {
                                  props.onChange(e.target.value.toLowerCase());
                                }}
                                value={props.value}
                              />
                            )}
                          />
                          {!!addFormErrors.key && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                                display: 'inline-block',
                              }}
                            >
                              {addFormErrors.key.message}
                              {addFormErrors.key.message ===
                                t('keys-table.errors.key.invalid') && (
                                <>
                                  {' '}
                                  <Link
                                    component='a'
                                    onClick={openKeyFormatModal}
                                  >
                                    {t('keys-table.valid-link')}
                                  </Link>
                                </>
                              )}
                            </FormHelperText>
                          )}
                        </td>
                        <td>
                          <FormControl>
                            <Input
                              {...addFormRegister('primaryValue')}
                              error={!!addFormErrors.primaryValue}
                              placeholder={`${t('keys-table.headings.primary-value')}...`}
                            />
                            {!!addFormErrors.primaryValue && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {addFormErrors.primaryValue.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Controller
                              name='placeholder'
                              control={addFormControl}
                              defaultValue={false}
                              render={({ field: props }) => (
                                <Checkbox
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </div>
                        </td>
                        <td>
                          <FormControl>
                            <Input
                              {...addFormRegister('context')}
                              error={!!addFormErrors.context}
                              placeholder={`${t('keys-table.headings.context')}...`}
                            />
                            {!!addFormErrors.context && (
                              <FormHelperText
                                sx={{
                                  color: joyUiTheme.vars.palette.danger[500],
                                }}
                              >
                                {addFormErrors.context.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              gap: '8px',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <IconButton
                              color='success'
                              variant='solid'
                              loading={isAddKeyLoading}
                              disabled={!isAddFormValid}
                              aria-label={t('common.confirm')}
                              onClick={() => submitAddKey()}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </IconButton>
                            <IconButton
                              color='danger'
                              variant='solid'
                              aria-label={t('common.cancel')}
                              onClick={() => {
                                addFormReset();
                                setAddingKey(false);
                                resetAddKey();
                              }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </IconButton>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td colSpan={showReview ? 6 : 5}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          {rows?.length === 0 && (
                            <div style={{ margin: '8px 0' }}>
                              {t('keys-table.no-keys')}
                            </div>
                          )}
                          {editable && (
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <Button
                                startDecorator={
                                  <FontAwesomeIcon icon={faPlus} />
                                }
                                onClick={() => setAddingKey(true)}
                                disabled={rowEditing !== undefined}
                              >
                                {t('keys-table.add-key')}
                              </Button>
                              <Button
                                startDecorator={
                                  <FontAwesomeIcon icon={faFolderPlus} />
                                }
                                onClick={() => setIsBulkImportOpen(true)}
                                disabled={rowEditing !== undefined}
                              >
                                {t('keys-table.bulk-import')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default KeysTable;
