class FileProcessingError extends Error {
    public fileName: string;
    public rowIndex?: number;
    public keyName?: string;

    public constructor(message: string, fileName: string, rowIndex?: number, keyName?: string) {
        super(message);
        this.fileName = fileName;
        this.rowIndex = rowIndex;
        this.keyName = keyName;
    }
}

export default FileProcessingError;