import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Select,
  Option,
} from '@mui/joy';
import React from 'react';
import { LanguageData } from '../../types/misc';
import CountryFlag from '../country-flag/country-flag';
import { useTranslation } from 'react-i18next';
import { useCookieContext } from '../../util/cookie-context';

export type I18nModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const languageOptions: LanguageData[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'español',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'ja',
    name: '日本人',
  },
  {
    code: 'fr',
    name: 'français',
  },
  {
    code: 'pt-PT',
    name: 'Português',
  },
  {
    code: 'it',
    name: 'italiano',
  },
  {
    code: 'nl',
    name: 'Nederlands',
  },
  {
    code: 'pl',
    name: 'Polskie',
  },
  {
    code: 'tr',
    name: 'Türk',
  },
  {
    code: 'zh',
    name: '中文（简体）',
  },
  {
    code: 'zh-TW',
    name: '中文（繁體）',
  },
];

const I18nModal = ({ isOpen, onClose }: I18nModalProps) => {
  const { t, i18n } = useTranslation();
  const [currentSelection, setCurrentSelection] = React.useState<
    LanguageData
  >(languageOptions.find((data) => data.code === i18n.language) ?? languageOptions[0]);
  const { setLangCookie } = useCookieContext();

  const handleLangChange = (lang: LanguageData | null) => {
    if (lang) {
      setCurrentSelection(lang);
      i18n.changeLanguage(lang.code);
      setLangCookie(lang.code);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>{t('change-lang.title')}</DialogTitle>
        <ModalClose />
        <DialogContent>
          <Select
            placeholder={`${t('change-lang.placeholder')}...`}
            startDecorator={
              currentSelection ? (
                <CountryFlag languageCode={currentSelection.code} />
              ) : undefined
            }
            value={currentSelection}
            onChange={(event, newValue) => handleLangChange(newValue)}
          >
            {languageOptions.map((lang) => (
              <Option value={lang} key={lang.code}>
                <CountryFlag languageCode={lang.code} />
                {lang.name}
              </Option>
            ))}
          </Select>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default I18nModal;
