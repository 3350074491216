import React from 'react';
import Card from '../card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faMagnifyingGlassChart,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';
import { Link, Skeleton, Tooltip } from '@mui/joy';
import Subtitle from '../subtitle/subtitle';
import { ReleaseTranslationValueStats } from '../../types/translation';
import CircularProgressPercent from '../circular-progress-percent/circular-progress-percent';
import { css } from 'aphrodite';
import languageTranslationStatsStyles from './language-translation-status.styles';
import CountryFlag from '../country-flag/country-flag';
import { useTranslation } from 'react-i18next';

export type LanguageTranslationStatusProps = {
  languageTranslationStats?: ReleaseTranslationValueStats[];
  onCollapse?: (collapsed: boolean) => void;
};

const LanguageTranslationStatusLoader = () => {
  return (
    <tr>
      <td>
        <Skeleton
          loading
          variant='rectangular'
          width={'100%'}
          height={'30px'}
        />
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton
            loading
            variant='rectangular'
            width={'30px'}
            height={'30px'}
          />
        </div>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton
            loading
            variant='rectangular'
            width={'30px'}
            height={'30px'}
          />
        </div>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton
            loading
            variant='rectangular'
            width={'30px'}
            height={'30px'}
          />
        </div>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Skeleton
            loading
            variant='rectangular'
            width={'30px'}
            height={'30px'}
          />
        </div>
      </td>
    </tr>
  );
};

const LanguageTranslationStatus = ({
  languageTranslationStats,
  onCollapse,
}: LanguageTranslationStatusProps) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [showTopGradient, setShowTopGradient] = React.useState(false);
  const [showBottomGradient, setShowBottomGradient] = React.useState(false);

  const listRef = React.useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleCollapse = () => {
    const newValue = !collapsed;
    setCollapsed(newValue);
    if (onCollapse) {
      onCollapse(newValue);
    }
  };

  const sortedTranslatedStats: ReleaseTranslationValueStats[] | undefined =
    React.useMemo(() => {
      return languageTranslationStats
        ?.map((statVal) => ({ ...statVal, langName: t(statVal.langName) }))
        .sort((a, b) => (a.langName > b.langName ? 1 : -1));
    }, [languageTranslationStats, t]);

  React.useEffect(() => {
    const langList = listRef.current;

    const handleScroll = () => {
      if (
        !collapsed &&
        languageTranslationStats &&
        languageTranslationStats.length > 10 &&
        langList &&
        langList.scrollTop > 0
      ) {
        setShowTopGradient(true);
      } else {
        setShowTopGradient(false);
      }

      if (
        !collapsed &&
        languageTranslationStats &&
        languageTranslationStats.length > 10 &&
        langList &&
        langList.scrollTop + langList.clientHeight < langList.scrollHeight
      ) {
        setShowBottomGradient(true);
      } else {
        setShowBottomGradient(false);
      }
    };

    // run initially
    handleScroll();

    langList?.addEventListener('scroll', handleScroll);

    return () => {
      langList?.removeEventListener('scroll', handleScroll);
    };
  }, [languageTranslationStats, collapsed]);

  return (
    <Card
      sx={{
        width: 'calc(calc(calc(100% - 128px) * 0.25) - 40px)',
        position: 'absolute',
        padding: '16px',
        zIndex: 2,
        gap: 0,
      }}
      data-testid='language-translation-status'
    >
      <>
        <Link component='button' onClick={handleCollapse} underline='none'>
          <div
            style={{
              margin: '8px 0',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '16px',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlassChart}
              color={theme.colour.primary}
              size='3x'
            />
            <h3 style={{ margin: 0, color: theme.colour.black }}>
              {t('lang-translation-status.title')}
            </h3>
            <FontAwesomeIcon
              icon={collapsed ? faAngleDown : faAngleUp}
              size='xl'
            />
          </div>
        </Link>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: collapsed ? 0 : `50vh`,
            transition: `all 0.5s ease`,
            overflow: 'auto',
          }}
          className={css(
            showTopGradient && languageTranslationStatsStyles.topGradient,
            showBottomGradient && languageTranslationStatsStyles.bottomGradient,
          )}
          ref={listRef}
        >
          <table>
            <thead>
              <tr>
                <th style={{ width: '40%' }}></th>
                <Tooltip
                  title={t('lang-translation-status.total.tooltip')}
                  placement='top'
                  arrow
                >
                  <th style={{ fontWeight: 'normal' }}>
                    <Subtitle style={{ fontSize: '10px' }}>
                      {t('lang-translation-status.total.label')}
                    </Subtitle>
                  </th>
                </Tooltip>
                <Tooltip
                  title={t('lang-translation-status.manual.tooltip')}
                  placement='top'
                  arrow
                >
                  <th style={{ fontWeight: 'normal' }}>
                    <Subtitle style={{ fontSize: '10px' }}>
                      {t('lang-translation-status.manual.label')}
                    </Subtitle>
                  </th>
                </Tooltip>
                <Tooltip
                  title={t('lang-translation-status.auto.tooltip')}
                  placement='top'
                  arrow
                >
                  <th style={{ fontWeight: 'normal' }}>
                    <Subtitle style={{ fontSize: '10px' }}>
                      {t('lang-translation-status.auto.label')}
                    </Subtitle>
                  </th>
                </Tooltip>
                <Tooltip
                  title={t('lang-translation-status.reviewed.tooltip')}
                  placement='top'
                  arrow
                >
                  <th style={{ fontWeight: 'normal' }}>
                    <Subtitle style={{ fontSize: '10px' }}>
                      {t('lang-translation-status.reviewed.label')}
                    </Subtitle>
                  </th>
                </Tooltip>
              </tr>
            </thead>
            <tbody>
              {sortedTranslatedStats ? (
                sortedTranslatedStats.map((data) => (
                  <tr key={data.lang}>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          fontSize: '14px',
                        }}
                      >
                        <CountryFlag languageCode={data.lang} />
                        {data.langName ?? t('common.unknown')}
                      </div>
                    </td>
                    <td
                      className={css(
                        languageTranslationStatsStyles.colContainer,
                      )}
                    >
                      <CircularProgressPercent
                        value={data.percentTranslated}
                        data-testid={`transtat-${data.lang}-translated`}
                      />
                    </td>
                    <td
                      className={css(
                        languageTranslationStatsStyles.colContainer,
                      )}
                    >
                      <CircularProgressPercent
                        value={data.percentManual}
                        data-testid={`transtat-${data.lang}-manual`}
                      />
                    </td>
                    <td
                      className={css(
                        languageTranslationStatsStyles.colContainer,
                      )}
                    >
                      <CircularProgressPercent
                        value={data.percentAuto}
                        opposingColour
                        data-testid={`transtat-${data.lang}-auto`}
                      />
                    </td>
                    <td
                      className={css(
                        languageTranslationStatsStyles.colContainer,
                      )}
                    >
                      <CircularProgressPercent
                        value={data.percentReviewed}
                        data-testid={`transtat-${data.lang}-review`}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <LanguageTranslationStatusLoader />
                  <LanguageTranslationStatusLoader />
                  <LanguageTranslationStatusLoader />
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    </Card>
  );
};

export default LanguageTranslationStatus;
