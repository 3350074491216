import { FileWithPath } from "react-dropzone";
import Papa from 'papaparse';
import FileProcessingError from "../../util/errors/file-processing-error";
import generalUtil from "../../util/general";
import { TranslationKeyData, TranslationKeyInput } from "../../types/translation";
import i18n from "../../util/i18n";

export type ParseKeysResult = {
    validKeys: TranslationKeyInput[];
    errors: FileProcessingError[];
}

export const validateCSVKeyRow = (row: Record<string, any>, fileName: string, rowIndex: number, existingKeys: string[]): FileProcessingError[] => {
    const errors: FileProcessingError[] = [];
    if (!row.key) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.required'), fileName, rowIndex));
    }

    if (row.key && (row.key.length === 0 || row.key.length > 255)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.length', { min: 1, max: 255 }), fileName, rowIndex, row.key));
    }

    if (row.key && !generalUtil.KEY_REGEX.test(row.key)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.invalid'), fileName, rowIndex, row.key));
    }

    if (row.key && existingKeys.includes(row.key)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.duplicate'), fileName, rowIndex, row.key));
    }

    if (!row.primaryValue) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.primary-value.required'), fileName, rowIndex, row.key));
    }

    if (row.primaryValue && (row.primaryValue.length === 0 || row.primaryValue.length > 2000)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.primary-value.length', { min: 1, max: 2000 }), fileName, rowIndex, row.key));
    }

    if (row.context && row.context.length > 2000) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.context.length', { max: 2000 }), fileName, rowIndex, row.key));
    }

    if (row.placeholder && !['true', 'false'].includes(row.placeholder.toLowerCase())) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.placeholder.invalid'), fileName, rowIndex, row.key));
    }

    return errors;
}

const parseCSVFile = (csvData: string, fileName: string): Promise<Papa.ParseResult<Record<string, any>>> => {
    return new Promise((resolve, reject) => {
        Papa.parse<Record<string, any>>(csvData, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => resolve(result),
            error: () => reject(new FileProcessingError(i18n.t('key-import.errors.processing-error'), fileName))
        });
    })
}

export const importKeysFromCSV = async (csvFile: FileWithPath, existingKeys: string[]): Promise<ParseKeysResult> => {
    try {
        const parsedFile = await parseCSVFile(await csvFile.text(), csvFile.name);
    
        const errors: FileProcessingError[] = [];
        const validKeys: TranslationKeyInput[] = [];

        parsedFile.data.forEach((row, rowIndex) => {
            const fileErrors = validateCSVKeyRow(row, csvFile.name, rowIndex, [...existingKeys, ...validKeys.map((data) => data.key)]);

            if (fileErrors.length > 0) {
                errors.push(...fileErrors);
            } else {
                validKeys.push({
                    key: row.key as string,
                    primaryValue: row.primaryValue as string,
                    context: row.context,
                    placeholder: row.placeholder.toLowerCase() === 'true',
                });
            }
        });

        return { validKeys, errors };
    } catch (err) {
        throw new FileProcessingError(i18n.t('key-import.errors.general-error'), csvFile.name);
    }
}

export const validateJSONParsedKey = (key: string, value: string, fileName: string, existingKeys: string[]): FileProcessingError[] => {
    const errors: FileProcessingError[] = [];
    if (!key) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.required'), fileName));
    }

    if (key && (key.length === 0 || key.length > 255)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.length', { min: 1, max: 255 }), fileName, undefined, key));
    }

    if (key && !generalUtil.KEY_REGEX.test(key)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.invalid'), fileName, undefined, key));
    }

    if (key && existingKeys.includes(key)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.key.duplicate'), fileName, undefined, key));
    }

    if (!value) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.primary-value.required'), fileName, undefined, key));
    }

    if (value && (value.length === 0 || value.length > 2000)) {
        errors.push(new FileProcessingError(i18n.t('key-import.errors.primary-value.length', { min: 1, max: 2000 }), fileName, undefined, key));
    }

    return errors;
}

export const importKeysFromJson = (jsonObj: Record<string, any>, fileName: string, existingKeys: string[], prefix?: string): ParseKeysResult => {
    const keys: TranslationKeyInput[] = [];
    const jsonParseErrors: FileProcessingError[] = [];

    for (const key in jsonObj) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        const value = jsonObj[key];

        if (typeof value === 'object' && value !== null) {
            const { validKeys, errors } = importKeysFromJson(value, fileName, [...existingKeys, ...keys.map((data) => data.key)], newKey);
            keys.push(...validKeys);
            jsonParseErrors.push(...errors);
        } else {
            const validationErrors = validateJSONParsedKey(newKey, String(value), fileName, existingKeys);
            if (validationErrors.length === 0) {
                keys.push({ key: newKey, primaryValue: String(value) });
            } else {
                jsonParseErrors.push(...validationErrors);
            }
        }
    }

    return { validKeys: keys, errors: jsonParseErrors };
}

export const importKeysFromFiles = async (uploadedFiles: FileWithPath[], existingKeys: TranslationKeyData[]): Promise<ParseKeysResult> => {
    const errors: FileProcessingError[] = [];
    const validKeys: TranslationKeyInput[] = [];

    const existingKeysStrings = existingKeys.map((data) => data.key);

    for (const file of uploadedFiles) {
        try {
            if (file.type === 'text/csv') {
                const csvResult = await importKeysFromCSV(file, existingKeysStrings);
                errors.push(...csvResult.errors);
                validKeys.push(...csvResult.validKeys);
            } else if (file.type === 'application/json') {
                try {
                    const jsonObj = JSON.parse(await file.text());
                    const jsonResult = importKeysFromJson(jsonObj, file.name, existingKeysStrings);
                    validKeys.push(...jsonResult.validKeys);
                    errors.push(...jsonResult.errors);
                } catch (err) {
                    throw new FileProcessingError(i18n.t('key-import.errors.invalid-json'), file.name);
                }
            } else {
                throw new FileProcessingError(i18n.t('key-import.errors.invalid-file-type'), file.name);
            }
        } catch (err) {
            errors.push(err instanceof FileProcessingError ? err : new FileProcessingError(i18n.t('errors.unknown-error'), file.name));
        }
    }
    return {
        validKeys,
        errors
    }
}