import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export type PageProps = {
    component: React.ReactElement;
    pageTitle: string;
}

const Page = ({ component, pageTitle }: PageProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>OneTranslate | {t(pageTitle)}</title>
            </Helmet>
            {component}
        </>
    )
}

export default Page;