import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type AutoTranslatedIndicatorProps = {
  initiatorName?: string;
};

const AutoTranslatedIndicator = ({
  initiatorName,
}: AutoTranslatedIndicatorProps) => {
  const joyUiTheme = useTheme();
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
      <div>
        <FontAwesomeIcon
          icon={faRobot}
          color={joyUiTheme.colorSchemes.light.palette.warning[400]}
          size='2x'
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontWeight: 'bold' }}>{t('translations.auto-translated')}</div>
        {initiatorName && (
          <div style={{ fontSize: '14px' }}>{t('translations.by')}{' '}{initiatorName}</div>
        )}
      </div>
    </div>
  );
};

export default AutoTranslatedIndicator;
