import React from 'react';
import { User } from '../../types/user';
import { css } from 'aphrodite';
import profileNameStyles from './profile-name.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../util/auth-hook';
import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export type ProfileNameProps = {
  user?: User;
  variant?: 'large' | 'small' | 'xsmall';
  tagline?: string;
  timeline?: string;
};

const ProfileName = ({
  user,
  variant = 'small',
  tagline,
  timeline,
}: ProfileNameProps) => {
  const { user: authenticatedUser } = useAuth();

  const { t } = useTranslation();

  return (
    <div
      className={css(profileNameStyles.container)}
      data-testid={`profile-name-${user?.id ?? 'unknown'}`}
    >
      <div
        className={css(profileNameStyles.nameAndProfileGroup)}
        style={{
          gap: variant === 'large' ? '16px' : '8px',
        }}
      >
        <div className={css(profileNameStyles[variant === 'xsmall' ? 'iconContainerSmall' : 'iconContainer'])}>
          {user?.picture ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={user.picture}
                width={'100%'}
                style={{ borderRadius: '50%' }}
                alt={user.name.full}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={faCircleUser}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            noWrap
            fontSize={variant === 'xsmall' ? '14px' : tagline ? '16px' : '18px'}
            fontWeight={500}
          >
            {user?.name.full ?? t('profile-name.unknown')}
            {authenticatedUser?.sub === user?.id && ` ${t('profile-name.you')}`}
          </Typography>
          {tagline && (
            <div className={css(profileNameStyles.tagline)}>{tagline}</div>
          )}
        </div>
      </div>
      <div className={css(profileNameStyles.timeline)}>{timeline ?? ''}</div>
    </div>
  );
};

export default ProfileName;
