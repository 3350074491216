import React from 'react';
import { Button, Skeleton, Typography } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faFileCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../types/navigation';
import ProjectCard from '../../components/project-card/project-card';
import Heading from '../../components/heading/heading';
import { useQuery } from 'react-query';
import projectService from '../../service/project';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import theme from '../../styles/theme';
import useAuth from '../../util/auth-hook';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    data: projectData,
    isFetching: isProjectDataFetching,
    isError: isProjectDataError,
    error: projectDataError,
    refetch: refetchProjectData,
  } = useQuery(
    'project-data',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getAllProjects(accessToken);
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  return (
    <>
      <LoadingMessage isLoading={isProjectDataFetching} />
      <ErrorMessagePopup
        isError={isProjectDataError}
        error={projectDataError}
        retry={refetchProjectData}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <Heading style={{ margin: '16px 0' }}>
          <>
            {t('dashboard.welcome', { userName: user?.name ?? 'User' })}{' '}
            &#128075;
          </>
        </Heading>
        <Button
          variant='solid'
          color={'primary'}
          startDecorator={<FontAwesomeIcon icon={faPlus} />}
          size='lg'
          onClick={() => navigate(Page.CreateProject)}
        >
          {t('dashboard.create-project')}
        </Button>
      </div>
      <div>
        {isProjectDataFetching ? (
          <div style={{ display: 'flex', gap: '24px' }}>
            <Skeleton
              loading={isProjectDataFetching}
              sx={{ borderRadius: '8px' }}
              variant='rectangular'
              width='360px'
              height='380px'
            />
            <Skeleton
              loading={isProjectDataFetching}
              sx={{ borderRadius: '8px' }}
              variant='rectangular'
              width='360px'
              height='380px'
            />
            <Skeleton
              loading={isProjectDataFetching}
              sx={{ borderRadius: '8px' }}
              variant='rectangular'
              width='360px'
              height='380px'
            />
          </div>
        ) : projectData?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: theme.colour.grey,
              padding: '24px',
            }}
          >
            <FontAwesomeIcon
              icon={faFileCircleQuestion}
              color='inherit'
              size='5x'
            />
            <h3>{t('dashboard.no-projects')}</h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <Typography textColor='inherit'>
                {t('dashboard.create-project-instruction.click')}{' '}
                <Typography
                  variant='outlined'
                  sx={{ padding: '6px', borderRadius: '8px' }}
                >
                  {t('dashboard.create-project-instruction.button')}
                </Typography>{' '}
                {t('dashboard.create-project-instruction.ending')}
              </Typography>
              <Typography textColor='inherit'>
                {t('dashboard.create-project-instruction.alternatively')}
              </Typography>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, 360px)',
              gridAutoRows: '380px',
              justifyContent: 'start',
              gap: '24px',
              width: '100%',
            }}
          >
            {projectData?.map((project) => (
              <ProjectCard project={project} key={project.id} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
