import React from 'react';
import {
  Accept,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';
import fileUploadStyles from './file-upload.styles';
import { css } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck, faFileCircleXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';
import joyUiTheme from '../../styles/joyui-theme';
import generalUtil from '../../util/general';
import { useTranslation } from 'react-i18next';

export type FileUploadProps = {
  caption?: string;
  subcaption?: string;
  acceptedFileTypes?: Accept;
  disabled?: boolean;
  onFileAccept?: (files: readonly FileWithPath[]) => void;
  onFileReject?: (files: readonly FileRejection[]) => void;
};

const FileUpload = ({
  caption = 'file-upload.caption',
  subcaption = 'file-upload.subcaption',
  acceptedFileTypes,
  disabled,
  onFileAccept,
  onFileReject,
}: FileUploadProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: generalUtil.convertFileSize(20, 'MB', 'bytes').value,
    maxFiles: 5,
    multiple: true,
    disabled,
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    if (onFileAccept && acceptedFiles.length > 0) {
      onFileAccept(acceptedFiles);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (onFileReject && fileRejections.length > 0) {
      onFileReject(fileRejections);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  return (
    <div
      {...getRootProps({
        className: css(
          fileUploadStyles.dropzone,
          fileUploadStyles[
            isDragActive && isDragAccept
              ? 'dropzoneAccept'
              : isDragActive && isDragReject
                ? 'dropzoneReject'
                : 'dropzoneBase'
          ],
        ),
      })}
    >
      <input {...getInputProps()} />
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        {isDragActive && isDragReject ? (
          <>
            <FontAwesomeIcon
              icon={faFileCircleXmark}
              size='2x'
              color={joyUiTheme.colorSchemes.light.palette.danger[600]}
            />
            <div style={{ fontWeight: 600 }}>
              {t('file-upload.invalid')}
            </div>
          </>
        ) : disabled ? (
          <>
            <FontAwesomeIcon
              icon={faFileCircleCheck}
              size='2x'
              color={theme.colour.grey}
            />
            <div style={{ fontWeight: 600 }}>
              {t('file-upload.max')}
            </div>
          </>
        ) : (
          <>
            <div>
              <FontAwesomeIcon
                icon={faUpload}
                size='2x'
                color={
                  isDragAccept
                    ? joyUiTheme.colorSchemes.light.palette.primary[500]
                    : theme.colour.grey
                }
              />
            </div>
            <div>
              <div className={css(fileUploadStyles.caption)}>{t(caption)}</div>
              <div className={css(fileUploadStyles.subcaption)}>
                {t(subcaption)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
