import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  DialogTitle,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import React from 'react';
import FileUpload from '../file-upload/file-upload';
import FileChip from '../file-chip/file-chip';
import {
  ErrorCode,
  FileError,
  FileRejection,
  FileWithPath,
} from 'react-dropzone';
import Message from '../message/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from 'react-query';
import useAuth from '../../util/auth-hook';
import miscService from '../../service/misc';
import fileDownload from 'js-file-download';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import { importKeysFromFiles } from './import-key-helper';
import {
  TranslationKeyData,
  TranslationKeyInput,
} from '../../types/translation';
import ImportKeyPreviewTable from './import-key-preview-table/import-key-preview-table';
import ErrorMessage from '../error-message/error-message';
import translationService from '../../service/translation';
import KeyFormatModal from '../key-format-modal/key-format-modal';
import { TFunction, Trans, useTranslation } from 'react-i18next';

export type ImportKeyModalProps = {
  isOpen: boolean;
  existingKeys: TranslationKeyData[];
  releaseId: number;
  onClose?: (keysAdded?: boolean) => void;
};

const convertFileErrorMessage = (error: FileError, t: TFunction): FileError => {
  if (error.code === ErrorCode.FileTooLarge) {
    return {
      ...error,
      message: t('key-import.file-size-error', { fileSize: '20MB' }),
    };
  }

  return error;
};

const convertFileErrorMessages = (
  fileRejection: FileRejection,
  t: TFunction,
): FileRejection => {
  return {
    ...fileRejection,
    errors: fileRejection.errors.map((error) =>
      convertFileErrorMessage(error, t),
    ),
  };
};

const ImportKeyModal = ({
  isOpen,
  existingKeys,
  releaseId,
  onClose,
}: ImportKeyModalProps) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<FileWithPath[]>([]);
  const [failedFiles, setFailedFiles] = React.useState<FileRejection[]>([]);
  const [showValidKeysModal, setShowValidKeysModal] = React.useState(false);

  const { getAccessTokenSilently } = useAuth();
  const { t } = useTranslation();

  const {
    isLoading: isCSVTemplateDownloading,
    isError: isCSVTemplateDownloadError,
    error: csvTemplateDownloadError,
    mutate: downloadCSVTemplate,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await miscService.downloadBulkCSVTemplate(accessToken);
    },
    {
      onSuccess: (data) => {
        fileDownload(data, `${t('key-import.template-filename')}.csv`);
      },
    },
  );

  const {
    data: processedFiles,
    isLoading: areFilesProcessing,
    isSuccess: isFilesProcessingSuccess,
    isError: isFilesProcessingError,
    error: filesProcessingError,
    mutate: processFiles,
    reset: resetProcessFiles,
  } = useMutation(async () => {
    return await importKeysFromFiles(uploadedFiles, existingKeys);
  });

  const {
    isLoading: isAddKeysLoading,
    isError: isAddKeysError,
    error: addKeysError,
    mutate: submitAddKeys,
    reset: resetAddKeys,
  } = useMutation(
    async (keys: TranslationKeyInput[]) => {
      const accessToken = await getAccessTokenSilently();

      return await translationService.addTranslationKeys(
        accessToken,
        releaseId,
        keys,
      );
    },
    {
      onSuccess: () => {
        handleModalClose(true);
      },
    },
  );

  const handleFileAccept = (files: readonly FileWithPath[]) => {
    setFailedFiles([]);

    const filesToAdd: FileWithPath[] = [];
    const filesToReject: FileRejection[] = [];

    for (const file of files) {
      if (
        uploadedFiles.some((existingFile) => existingFile.name === file.name) ||
        filesToAdd.some((fileToAdd) => fileToAdd.name === file.name)
      ) {
        filesToReject.push({
          file,
          errors: [
            {
              message: t('key-import.file-already-exists'),
              code: 'file-already-exists',
            },
          ],
        });
      } else if (uploadedFiles.length + filesToAdd.length >= 5) {
        filesToReject.push({
          file,
          errors: [
            {
              message: t('key-import.max'),
              code: 'max-num-files',
            },
          ],
        });
      } else {
        filesToAdd.push(file);
      }
    }

    setUploadedFiles((currentFiles) => [...currentFiles, ...filesToAdd]);

    if (filesToReject.length > 0) {
      setFailedFiles((currentFiles) => [
        ...currentFiles,
        ...filesToReject.map((file) => convertFileErrorMessages(file, t)),
      ]);
    }
  };

  const handleFileRejection = (files: readonly FileRejection[]) => {
    setFailedFiles([...files.map((file) => convertFileErrorMessages(file, t))]);
  };

  const handleFileDeletion = (fileName: string) => {
    const filesWithoutDeletedFile = uploadedFiles.filter(
      (file) => file.name !== fileName,
    );
    setUploadedFiles(filesWithoutDeletedFile);
    setFailedFiles([]);
  };

  const handleModalClose = (keysAdded: boolean) => {
    setUploadedFiles([]);
    setFailedFiles([]);
    resetProcessFiles();
    resetAddKeys();
    if (onClose) {
      onClose(keysAdded);
    }
  };

  const handleConfirm = (keys: TranslationKeyInput[]) => {
    submitAddKeys(keys);
  };

  const handleCancel = () => {
    resetProcessFiles();
    resetAddKeys();
  };

  return (
    <Modal open={isOpen} onClose={() => handleModalClose(false)}>
      <>
        <KeyFormatModal
          isOpen={showValidKeysModal}
          onClose={() => setShowValidKeysModal(false)}
        />
        <ErrorMessagePopup
          isError={isCSVTemplateDownloadError}
          error={csvTemplateDownloadError}
          retry={downloadCSVTemplate}
        />
        <ModalDialog
          minWidth={'750px'}
          maxWidth={'750px'}
          sx={{ overflow: 'auto' }}
        >
          <DialogTitle>
            {isFilesProcessingSuccess && processedFiles
              ? t('key-import.confirm-title')
              : t('key-import.title')}
          </DialogTitle>
          <ModalClose />
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {isFilesProcessingSuccess &&
            processedFiles &&
            processedFiles.validKeys.length > 0 ? (
              <div>{t('key-import.review')}</div>
            ) : (
              <>
                <div>{t('key-import.instruction')}</div>
                <div style={{ fontSize: '14px' }}>
                  {t('key-import.template-download')}{' '}
                  <Link
                    fontSize='inherit'
                    endDecorator={<FontAwesomeIcon icon={faDownload} />}
                    component={'a'}
                    onClick={() => downloadCSVTemplate()}
                    disabled={isCSVTemplateDownloading}
                  >
                    {t('common.here')}
                  </Link>
                </div>
                <AccordionGroup
                  variant='soft'
                  sx={{ borderRadius: '8px', padding: '8px' }}
                >
                  <Accordion>
                    <AccordionSummary>
                      {t('key-import.csv-guidance.title')}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '8px',
                        }}
                      >
                        <Typography>
                          <Trans
                            i18nKey='key-import.csv-guidance.columns'
                            components={{ b: <b /> }}
                            values={{ num: 4 }}
                          />{' '}
                          <Typography fontFamily={'monospace'} variant='soft'>
                            key
                          </Typography>
                          ,
                          <Typography fontFamily={'monospace'} variant='soft'>
                            primaryValue
                          </Typography>
                          ,
                          <Typography fontFamily={'monospace'} variant='soft'>
                            placeholder
                          </Typography>{' '}
                          {t('common.and')}
                          <Typography fontFamily={'monospace'} variant='soft'>
                            context
                          </Typography>
                          .
                        </Typography>
                        <div>{t('key-import.csv-guidance.rules')}</div>
                        <div>
                          <ul>
                            <li>
                              <Typography>
                                <Typography
                                  fontFamily={'monospace'}
                                  variant='soft'
                                >
                                  key
                                </Typography>{' '}
                                {'- '}
                                {t('key-import.csv-guidance.key-rule', {
                                  numChars: 255,
                                })}{' '}
                                <Link
                                  component='a'
                                  onClick={() => setShowValidKeysModal(true)}
                                >
                                  {t('key-import.csv-guidance.valid-key')}
                                </Link>
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                <Typography
                                  fontFamily={'monospace'}
                                  variant='soft'
                                >
                                  primaryValue
                                </Typography>{' '}
                                {'- '}
                                {t(
                                  'key-import.csv-guidance.primary-value-rule',
                                  { numChars: 2000 },
                                )}
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                <Typography
                                  fontFamily={'monospace'}
                                  variant='soft'
                                >
                                  placeholder
                                </Typography>{' '}
                                {'- '}
                                {t('key-import.csv-guidance.placeholder-rule')}
                              </Typography>
                            </li>
                            <li>
                              <Typography>
                                <Typography
                                  fontFamily={'monospace'}
                                  variant='soft'
                                >
                                  context
                                </Typography>{' '}
                                {'- '}
                                {t('key-import.csv-guidance.context-rule', {
                                  numChars: 2000,
                                })}
                              </Typography>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
              </>
            )}

            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              {!isFilesProcessingSuccess && (
                <>
                  <FileUpload
                    onFileAccept={handleFileAccept}
                    onFileReject={handleFileRejection}
                    disabled={uploadedFiles.length >= 5}
                    acceptedFileTypes={{
                      'application/json': ['.json'],
                      'text/csv': ['.csv'],
                    }}
                  />
                  {failedFiles.length > 0 && (
                    <Message
                      variant='danger'
                      title={t('key-import.errors.invalid-file-title')}
                    >
                      <>
                        <div>{t('key-import.errors.invalid-file')}</div>
                        <ul style={{ margin: '8px 0' }}>
                          {failedFiles.map((file, index) => (
                            <li key={`${index}-${file.file.name}`}>
                              <b>{file.file.name}</b> - {file.errors[0].message}
                            </li>
                          ))}
                        </ul>
                        <div>{t('errors.try-again')}</div>
                      </>
                    </Message>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                    }}
                  >
                    {uploadedFiles.map((file, index) => (
                      <FileChip
                        key={`${index}-${file.name}`}
                        fileName={file.name}
                        fileSize={file.size}
                        fileType={file.type}
                        onDelete={(fileName) => handleFileDeletion(fileName)}
                      />
                    ))}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      startDecorator={<FontAwesomeIcon icon={faFileImport} />}
                      disabled={uploadedFiles.length === 0}
                      onClick={() => processFiles()}
                      loading={areFilesProcessing}
                    >
                      {'key-import.import'}
                    </Button>
                  </div>
                </>
              )}
              <ErrorMessage
                isError={isFilesProcessingError}
                error={filesProcessingError}
              />
              {isFilesProcessingSuccess &&
                processedFiles.validKeys.length === 0 && (
                  <Message
                    variant='danger'
                    title={t('key-import.errors.no-keys-title')}
                  >
                    {t('key-import.errors.no-keys')}
                  </Message>
                )}
              {isFilesProcessingSuccess &&
                processedFiles?.errors?.length > 0 && (
                  <Message
                    variant='danger'
                    title={t('key-import.errors.process-error-title')}
                  >
                    <div>
                      {t('key-import.errors.process-error')}
                    </div>
                    <ul style={{ margin: 0 }}>
                      {processedFiles?.errors?.map((error, index) => (
                        <li
                          key={`${error.fileName}-${error.rowIndex}-${index}`}
                        >
                          <b>
                            {error.keyName && `${error.keyName} - `}
                            {error.fileName}
                            {error.rowIndex !== undefined &&
                              ` - ${t('key-import.errors.row-no')} ${error.rowIndex + 2}`}
                          </b>
                          : {error.message}
                        </li>
                      ))}
                    </ul>
                  </Message>
                )}
              {isFilesProcessingSuccess && processedFiles && (
                <ImportKeyPreviewTable
                  keys={processedFiles.validKeys}
                  existingKeys={existingKeys}
                  isAddKeyError={isAddKeysError}
                  addKeyError={addKeysError}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                  isSubmitting={isAddKeysLoading}
                />
              )}
            </div>
          </div>
        </ModalDialog>
      </>
    </Modal>
  );
};

export default ImportKeyModal;
