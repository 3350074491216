import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../i18n/en/en.json';
import zhCN from '../i18n/zh/zh.json';
import zhTW from '../i18n/zh-TW/zh-TW.json';
import nl from '../i18n/nl/nl.json';
import fr from '../i18n/fr/fr.json';
import de from '../i18n/de/de.json';
import it from '../i18n/it/it.json'
import ja from '../i18n/ja/ja.json';
import pl from '../i18n/pl/pl.json';
import ptPT from '../i18n/pt-PT/pt-PT.json';
import es from '../i18n/es/es.json';
import tr from '../i18n/tr/tr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { OTCookie } from './cookie-context';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
        en: { translation: en },
        zh: { translation: zhCN },
        'zh-TW': { translation: zhTW },
        nl: { translation: nl },
        fr: { translation: fr },
        de: { translation: de },
        it: { translation: it },
        ja: { translation: ja },
        pl: { translation: pl },
        'pt-PT': { translation: ptPT },
        es: { translation: es },
        tr: { translation: tr }
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh', 'zh-TW', 'nl', 'fr', 'de', 'it', 'ja', 'pl', 'pt-PT', 'es', 'tr'],
    detection: {
      order: ['cookie', 'navigator'],
      lookupCookie: OTCookie.Lang,
    },
});

export default i18n;