import {
  faFileCircleQuestion,
  faFileCode,
  faFileCsv,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'aphrodite';
import React from 'react';
import fileChipStyles from './file-chip.styles';
import theme from '../../styles/theme';
import commonStyles from '../../styles/common.styles';
import generalUtil from '../../util/general';

export type FileChipProps = {
  fileName: string;
  fileSize?: number;
  fileType?: string;
  onDelete?: (fileName: string) => void;
};

const FileChip = ({
  fileName,
  fileSize,
  fileType,
  onDelete,
}: FileChipProps) => {
  const fileSizeConversion = React.useMemo(
    () => generalUtil.convertFileSize(fileSize ?? 0, 'bytes'),
    [fileSize],
  );

  return (
    <div className={css(fileChipStyles.container)}>
      <div>
        <FontAwesomeIcon
          icon={
            fileType === 'text/csv'
              ? faFileCsv
              : fileType === 'application/json'
                ? faFileCode
                : faFileCircleQuestion
          }
          size='xl'
          color={theme.colour.darkGrey}
        />
      </div>
      <div>
        <div className={css(fileChipStyles.fileName)}>{fileName}</div>
        {fileSize && (
          <div className={css(fileChipStyles.fileSize)}>{fileSizeConversion.value} {fileSizeConversion.unit}</div>
        )}
      </div>
      <button
        className={css(commonStyles.nativeReset)}
        onClick={onDelete ? () => onDelete(fileName) : undefined}
      >
        <FontAwesomeIcon icon={faXmark} color={theme.colour.grey} />
      </button>
    </div>
  );
};

export default FileChip;
