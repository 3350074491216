import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from './navigation-bar/navigation-bar';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { css } from 'aphrodite';
import layoutStyles from './layout.styles';
import Footer from './footer/footer';
import CookiePreferences from '../cookie-preferences/cookie-preferences';
import RouterScroll from '../../util/router-scroll';

const Layout = () => {
  return (
    <>
      <RouterScroll />
      <NavigationBar />
      <CookiePreferences />
      <div className={css(layoutStyles.contentContainer)}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default withAuthenticationRequired(Layout);
