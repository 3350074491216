import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from '@mui/joy';
import React from 'react';
import { Language, LanguageData } from '../../types/misc';
import {
  TranslationData,
  UpdateTranslationValueResponse,
} from '../../types/translation';
import TranslateComponent from '../translate/translate';
import {
  mapFormValuesToApiData,
  translateFormValidationSchema,
  TranslationFormValues,
} from '../../pages/translate/translate.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Message from '../message/message';
import { useMutation } from 'react-query';
import useAuth from '../../util/auth-hook';
import translationService from '../../service/translation';
import ErrorMessage from '../error-message/error-message';
import { useTranslation } from 'react-i18next';

export type EditTranslationModalProps = {
  isOpen: boolean;
  lang: LanguageData;
  defaultLang?: Language;
  translationData?: TranslationData;
  releaseId: number;
  onClose?: () => void;
  onTranslationUpdate?: (response: UpdateTranslationValueResponse) => void;
};

const EditTranslationModal = ({
  isOpen,
  lang,
  defaultLang,
  translationData,
  releaseId,
  onClose,
  onTranslationUpdate,
}: EditTranslationModalProps) => {
  const form = useForm<TranslationFormValues>({
    resolver: yupResolver(translateFormValidationSchema),
    mode: 'all',
  });

  const { getAccessTokenSilently } = useAuth();
  const { t } = useTranslation();

  const translationDataArray = React.useMemo(() => {
    if (translationData) {
      return [translationData];
    }
  }, [translationData]);

  const {
    isLoading: isSaveTranslationsLoading,
    isError: isSaveTranslationsError,
    error: saveTranslationsError,
    mutate: saveTranslations,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await translationService.updateTranslationValues(
        accessToken,
        Number(releaseId),
        lang.code,
        mapFormValuesToApiData(form.getValues()),
      );
    },
    {
      onSuccess: (response) => {
        if (onTranslationUpdate) {
          onTranslationUpdate(response);
        }
        handleClose();
      },
    },
  );

  const handleClose = () => {
    form.reset();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalDialog minWidth={'800px'}>
        <DialogTitle>{t('translations.edit.title')}</DialogTitle>
        <ModalClose />
        <DialogContent>
          {translationData ? (
            <>
              <TranslateComponent
                lang={lang}
                defaultLang={defaultLang}
                existingTranslations={translationDataArray}
                disabled={isSaveTranslationsLoading}
                form={form}
                card={false}
                showCount={false}
                onSave={saveTranslations}
                isSaving={isSaveTranslationsLoading}
              />
              <ErrorMessage
                isError={isSaveTranslationsError}
                error={saveTranslationsError}
              />
            </>
          ) : (
            <Message variant='danger' title={t('errors.general')} icon>
              <div>{t('translations.edit.error')}</div>
              <div style={{ fontSize: '14px' }}>
                {t('errors.code')} UI_ERR_NO_DATA
              </div>
            </Message>
          )}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default EditTranslationModal;
