import {
  faAngleLeft,
  faCheck,
  faCircleInfo,
  faComments,
  faDownload,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Link,
  Select,
  Skeleton,
  Typography,
  Option,
  Switch,
} from '@mui/joy';
import React from 'react';
import { Page } from '../../types/navigation';
import {
  useNavigate,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import Heading from '../../components/heading/heading';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import generalUtil from '../../util/general';
import useAuth from '../../util/auth-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import projectService from '../../service/project';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { AppRole, ProjectRole } from '../../types/user';
import {
  BasicReleaseDetails as BasicReleaseDetailsType,
  ReleaseStatus,
} from '../../types/project';
import TranslationsTable from '../../components/translations-table/translations-table';
import { LanguageData } from '../../types/misc';
import Subtitle from '../../components/subtitle/subtitle';
import CountryFlag from '../../components/country-flag/country-flag';
import theme from '../../styles/theme';
import Card from '../../components/card/card';
import ApproveReleaseConfirmationModal from '../../components/approve-release-confirmation-modal/approve-release-confirmation-modal';
import Message from '../../components/message/message';
import LanguageTranslationStatus from '../../components/language-translation-status/language-translation-status';
import translationService from '../../service/translation';
import gitService from '../../service/git';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { useCookieContext } from '../../util/cookie-context';
import AutoTranslationConfirmationModal from '../../components/auto-translation-confirmation-modal/auto-translation-confirmation-modal';

const determineCanApprove = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (
    basicReleaseDetails?.status.code === ReleaseStatus.Translating ||
    basicReleaseDetails?.status.code ===
      ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const determineCanEditValues = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (basicReleaseDetails?.status.code === ReleaseStatus.Translating) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole ===
        ProjectRole.TranslationCoordinator ||
      basicReleaseDetails.userProjectRole === ProjectRole.Translator
    );
  }

  if (
    basicReleaseDetails?.status.code ===
    ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const determineCanReview = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (
    basicReleaseDetails?.status.code ===
    ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const ReleaseTranslations = () => {
  const { projectId, releaseId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    setHighlightUntranslatedPreference,
    highlightUntranslatedPreference,
  } = useCookieContext();

  const [language, setLanguage] = React.useState<LanguageData | undefined>();
  const [fullWidth, setFullWidth] = React.useState(false);
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] =
    React.useState(false);
  const [isAutoTranslateConfirmationOpen, setIsAutoTranslateConfirmationOpen] =
    React.useState(false);
  const [hasStatusChanged, setHasStatusChanged] = React.useState(false);
  const [highlightUntranslated, setHighlightUntranslated] = React.useState(
    highlightUntranslatedPreference ?? false,
  );

  const { getAccessTokenSilently, userPerms } = useAuth();

  const {
    data: basicReleaseDetails,
    isFetching: isBasicReleaseDetailsLoading,
    isError: isBasicReleaseDetailsError,
    error: basicReleaseDetailsError,
    refetch: refetchBasicReleaseDetails,
  } = useQuery(
    ['basic-release-details', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getBasicReleaseDetails(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details-no-versions', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
        false,
        true,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const sortedTranslatedLangOptions: LanguageData[] | undefined =
    React.useMemo(() => {
      return projectDetails?.langs
        .map((lang) => ({ ...lang, name: t(lang.name) }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    }, [projectDetails, i18n.language]);

  const {
    data: approveResponse,
    isLoading: isApproveLoading,
    isError: isApproveError,
    isSuccess: isApproveSuccess,
    error: approveError,
    mutate: submitApproval,
    reset: resetSubmitApproval,
  } = useMutation(
    async (status?: ReleaseStatus) => {
      const accessToken = await getAccessTokenSilently();

      if (status === ReleaseStatus.ClientReviewPostTranslation) {
        return await gitService.release(accessToken, Number(releaseId));
      } else {
        return await projectService.approveRelease(
          accessToken,
          Number(releaseId),
          Number(projectId),
        );
      }
    },
    {
      onSuccess: (release) => {
        if (release.status !== ReleaseStatus.Released) {
          closePage();
        }
      },
    },
  );

  const {
    data: languageTranslationStats,
    isFetching: isLanguageTranslationStatsLoading,
    isError: isLanguageTranslationStatsError,
    error: languageTranslationStatsError,
    refetch: refetchLanguageTranslationStats,
  } = useQuery(
    ['language-translation-stats', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getReleaseTranslationStats(
        accessToken,
        Number(releaseId),
      );
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    isLoading: isReleaseFileDownloading,
    isError: isReleaseFileDownloadError,
    error: releaseFileDownloadError,
    mutate: downloadReleaseFile,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await gitService.downloadReleaseFiles(
        accessToken,
        Number(releaseId),
      );
    },
    {
      onSuccess: (data) => {
        fileDownload(data.file, data.fileName);
      },
    },
  );

  const handleApprovalConfirm = () => {
    submitApproval(basicReleaseDetails?.status.code);
  };

  const handleApprovalCancel = () => {
    setIsApproveConfirmationOpen(false);
    resetSubmitApproval();
  };

  const handleHighlightUntranslatedToggle = (checked: boolean) => {
    setHighlightUntranslated(checked);
    setHighlightUntranslatedPreference(checked);
  };

  const handleGithubNav = () => {
    if (basicReleaseDetails?.githubUrl) {
      window.open(basicReleaseDetails.githubUrl, '_blank');
    }
  };

  const closePage = () => {
    queryClient.invalidateQueries({
      queryKey: ['project-details', projectId],
    });
    queryClient.invalidateQueries({
      queryKey: ['project-details-no-versions', projectId],
    });
    queryClient.invalidateQueries({
      queryKey: ['basic-release-details', releaseId],
    });
    resetSubmitApproval();
    navigate(`${Page.ProjectRoute}/${projectId}`);
  };

  const canEditValues = React.useMemo(
    () => determineCanEditValues(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const canApprove = React.useMemo(
    () => determineCanApprove(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const canReview = React.useMemo(
    () => determineCanReview(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const showTranslationButtons = React.useMemo(() => {
    if (!language) {
      return false;
    }

    const selectedLangComplete =
      languageTranslationStats?.find((stats) => stats.lang === language.code)
        ?.percentTranslated === 100;

    return !selectedLangComplete;
  }, [language, languageTranslationStats]);

  const handleTranslationUpdate = (statusChange: boolean) => {
    refetchLanguageTranslationStats();
    if (statusChange) {
      setHasStatusChanged(true);
      refetchBasicReleaseDetails();
      refetchProjectDetails();
    }
  };

  const handleTranslationReview = () => {
    refetchLanguageTranslationStats();
  };

  const handleAutoTranslateClose = (hasStatusChanged: boolean) => {
    if (hasStatusChanged) {
      setHasStatusChanged(true);
      refetchBasicReleaseDetails();
      refetchProjectDetails();
    }
    setIsAutoTranslateConfirmationOpen(false);
  };

  React.useEffect(() => {
    const hasStatusChangedQueryParam = queryParams.get('statusChanged');
    if (
      hasStatusChangedQueryParam === 'true' &&
      basicReleaseDetails?.status.code ===
        ReleaseStatus.ClientReviewPostTranslation
    ) {
      setHasStatusChanged(true);
    }
  }, [queryParams, basicReleaseDetails]);

  React.useEffect(() => {
    const previewLang = queryParams.get('lang');
    const langData = sortedTranslatedLangOptions?.find(
      (data) => data.code === previewLang,
    );

    if (langData) {
      setLanguage(langData);
    } else if (sortedTranslatedLangOptions) {
      queryParams.delete('lang');
      setQueryParams(queryParams);
    }
  }, [queryParams, sortedTranslatedLangOptions, setQueryParams]);

  return (
    <>
      <AutoTranslationConfirmationModal
        isOpen={isAutoTranslateConfirmationOpen}
        lang={language}
        releaseId={releaseId}
        onClose={handleAutoTranslateClose}
      />
      <ApproveReleaseConfirmationModal
        isOpen={isApproveConfirmationOpen}
        handleCancel={handleApprovalCancel}
        handleConfirm={handleApprovalConfirm}
        handleConfirmationClose={closePage}
        releaseName={basicReleaseDetails?.versionName}
        status={basicReleaseDetails?.status}
        isLoading={isApproveLoading}
        isError={isApproveError}
        error={approveError}
        showConfirmation={
          isApproveSuccess &&
          basicReleaseDetails?.status.code ===
            ReleaseStatus.ClientReviewPostTranslation
        }
        gitUrl={approveResponse?.gitUrl}
        allReviewed={languageTranslationStats?.every(
          (lang) => lang.percentReviewed === 100,
        )}
        allTranslated={languageTranslationStats?.every(
          (lang) => lang.percentTranslated === 100,
        )}
      />
      <LoadingMessage
        isLoading={
          isBasicReleaseDetailsLoading ||
          isProjectDetailsFetching ||
          isLanguageTranslationStatsLoading
        }
      />
      <ErrorMessagePopup
        isError={isBasicReleaseDetailsError}
        error={basicReleaseDetailsError}
        retry={refetchBasicReleaseDetails}
      />
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <ErrorMessagePopup
        isError={isLanguageTranslationStatsError}
        error={languageTranslationStatsError}
        retry={refetchLanguageTranslationStats}
      />
      <ErrorMessagePopup
        isError={isReleaseFileDownloadError}
        error={releaseFileDownloadError}
        retry={downloadReleaseFile}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        component={RouterLink}
        to={`${Page.ProjectRoute}/${projectId}`}
      >
        {t('page-title.project-details')}
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>
        {t('page-title.translations')}
      </Heading>
      <div style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '24px' }}>
          <div>
            <Typography fontSize='14px'>
              <>
                {t('translations.intro-text.base')}
                {canEditValues && (
                  <>{` ${t('translations.intro-text.edit')}`}</>
                )}
                {canApprove && (
                  <>{` ${t('translations.intro-text.approve')}`}</>
                )}
              </>
            </Typography>
          </div>
          {canApprove && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Button
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
                color='success'
                onClick={() => setIsApproveConfirmationOpen(true)}
              >
                {t('common.approve')}
              </Button>
            </div>
          )}
          {basicReleaseDetails?.status.code === ReleaseStatus.Released && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Button
                startDecorator={<FontAwesomeIcon icon={faGithub} />}
                sx={{ textWrap: 'nowrap' }}
                color='neutral'
                disabled={!basicReleaseDetails.githubUrl}
                onClick={handleGithubNav}
              >
                {t('translations.github-link')}
              </Button>
              <Button
                startDecorator={<FontAwesomeIcon icon={faDownload} />}
                sx={{ textWrap: 'nowrap' }}
                onClick={() => downloadReleaseFile()}
                loading={isReleaseFileDownloading}
              >
                {t('translations.download-json')}
              </Button>
            </div>
          )}
        </div>
        <BasicReleaseDetails
          projectName={generalUtil.formatData(basicReleaseDetails?.projectName)}
          versionName={generalUtil.formatData(basicReleaseDetails?.versionName)}
          isLoading={isBasicReleaseDetailsLoading}
        />
      </div>
      <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '16px',
            height: '96px',
          }}
        >
          <Card sx={{ flexGrow: 1, maxWidth: 'calc(75% - 32px)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{ display: 'flex', gap: '24px', alignItems: 'center' }}
              >
                <div style={{ width: '250px' }}>
                  <Subtitle>{t('common.language')}</Subtitle>
                  <Skeleton
                    variant='rectangular'
                    loading={isProjectDetailsFetching}
                  >
                    <Select
                      placeholder={`${t('common.language')}...`}
                      startDecorator={
                        language ? (
                          <CountryFlag languageCode={language.code} />
                        ) : undefined
                      }
                      value={language}
                      onChange={(event, newValue) => {
                        setLanguage(newValue as LanguageData);
                      }}
                    >
                      {sortedTranslatedLangOptions?.map((lang) => (
                        <Option value={lang} key={lang.code}>
                          <CountryFlag languageCode={lang.code} />
                          {lang.name}
                        </Option>
                      ))}
                    </Select>
                  </Skeleton>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Subtitle>
                    {t('translations.highlight-untranslated')}
                  </Subtitle>
                  <div
                    style={{
                      flexGrow: 1,
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Switch
                      size='lg'
                      checked={highlightUntranslated}
                      onChange={(event) =>
                        handleHighlightUntranslatedToggle(event.target.checked)
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {showTranslationButtons && (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      startDecorator={<FontAwesomeIcon icon={faComments} />}
                      onClick={() =>
                        navigate(
                          `${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.Translate}/${language?.code}`,
                        )
                      }
                    >
                      {t('project-card.translate')}
                    </Button>
                    <Button
                      startDecorator={<FontAwesomeIcon icon={faRobot} />}
                      onClick={() => setIsAutoTranslateConfirmationOpen(true)}
                    >
                      {t('translate.auto-translate')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              maxWidth: 'calc(25% - 16px)',
            }}
          >
            <LanguageTranslationStatus
              languageTranslationStats={languageTranslationStats}
              onCollapse={(collapsed) => setFullWidth(collapsed)}
            />
          </div>
        </div>
        <div
          style={{
            width: fullWidth ? '100%' : '75%',
            transition: 'all 0.5s ease',
          }}
        >
          {hasStatusChanged && (
            <div style={{ marginBottom: '16px' }}>
              <Message
                variant='success'
                title={t('project-details.release-complete.heading')}
                onClose={() => setHasStatusChanged(false)}
              >
                <div>
                  <Typography sx={{ color: 'inherit' }}>
                    {t('project-details.release-complete.body')}{' '}
                    {!canEditValues &&
                      t('project-details.release-complete.no-changes')}
                  </Typography>
                </div>
              </Message>
            </div>
          )}
          {language ? (
            <TranslationsTable
              language={language}
              defaultLanguage={projectDetails?.defaultLang.code}
              releaseId={Number(releaseId)}
              editable={canEditValues}
              highlightUntranslated={highlightUntranslated}
              onTranslationUpdate={handleTranslationUpdate}
              showReview={canReview}
              onReview={handleTranslationReview}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                marginTop: '16px',
                color: theme.colour.grey,
              }}
            >
              <FontAwesomeIcon icon={faCircleInfo} size='2x' />
              <Typography sx={{ color: 'inherit' }}>
                {t('translations.select-language')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReleaseTranslations;
