import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type KeyFormatModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CodeStyleWrapper = ({ children }: { children?: string }) => {
  return (
    <Typography
      variant='soft'
      fontFamily={'monospace'}
      sx={{ fontSize: '14px' }}
    >
      {children}
    </Typography>
  );
};

const KeyFormatModal = ({ isOpen, onClose }: KeyFormatModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>{t('valid-key-format.title')}</DialogTitle>
        <ModalClose />
        <DialogContent>
          <div>{t('valid-key-format.must')}</div>
          <ul>
            <li>{t('valid-key-format.length', { max: 255 })}</li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.characters'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.separator'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.double-dots'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.leading-trailing-dots'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
            <li>{t('valid-key-format.no-spaces')}</li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.start-number'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <Trans
                  i18nKey='valid-key-format.parent-child'
                  components={{ codestyle: <CodeStyleWrapper /> }}
                />
              </Typography>
            </li>
          </ul>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default KeyFormatModal;
