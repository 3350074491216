import React from 'react';
import theme from '../../styles/theme';
import { Button } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Page } from '../../types/navigation';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '128px 0',
        gap: '54px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1
          style={{ fontSize: '144px', margin: 0, color: theme.colour.primary }}
        >
          404
        </h1>
        <h2 style={{ fontSize: '54px', margin: 0 }}>{t('not-found.title')}</h2>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h3 style={{ fontSize: '24px' }}>{t('not-found.nav-back')}</h3>
        <Link to={Page.Dashboard} data-testid='button-href'>
          <Button
            size='lg'
            startDecorator={<FontAwesomeIcon icon={faArrowRight} />}
          >
            {t('not-found.dashboard-link')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
