import { StyleSheet } from "aphrodite";
import theme from "../../styles/theme";

const fileChipStyles = StyleSheet.create({
    container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        borderColor: '#ABABAB',
        padding: '8px 16px',
        alignItems: 'center',
        gap: '16px',
        width: 'fit-content',
        minHeight: '40px',
        backgroundColor: theme.colour.xLightGrey,
    },
    fileName: {
        fontSize: '14px',
        color: theme.colour.darkGrey
    },
    fileSize: {
        fontSize: '12px',
        color: theme.colour.grey,
    }
});

export default fileChipStyles;