import {
  faCircleCheck,
  faCircleExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faXmark,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useTheme } from '@mui/joy';
import React from 'react';

export type MessageProps = {
  variant: 'success' | 'warning' | 'danger' | 'primary';
  title?: string;
  children: React.ReactNode;
  // true = uses variant default
  // false = no icon
  // IconDefinition overrides default
  icon?: boolean | IconDefinition;
  onClose?: () => void;
  style?: React.CSSProperties;
};

const Message = ({
  variant,
  title,
  children,
  icon = true,
  onClose,
  style,
}: MessageProps) => {
  const joyUiTheme = useTheme();
  return (
    <div
      style={{
        color:
          joyUiTheme.colorSchemes.light.palette[variant][
            variant === 'warning' ? 500 : 600
          ],
        backgroundColor: joyUiTheme.colorSchemes.light.palette[variant][100],
        border: `1px ${joyUiTheme.colorSchemes.light.palette[variant][variant === 'warning' ? 500 : 600]} solid`,
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        ...style,
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {icon !== false && (
            <FontAwesomeIcon
              icon={
                icon === true
                  ? variant === 'danger'
                    ? faCircleExclamation
                    : variant === 'warning'
                      ? faExclamationTriangle
                      : variant === 'primary'
                        ? faInfoCircle
                        : faCircleCheck
                  : icon
              }
              color={
                joyUiTheme.colorSchemes.light.palette[variant][
                  variant === 'warning' ? 500 : 600
                ]
              }
            />
          )}
          {title && <b>{title}</b>}
        </div>
        {onClose && (
          <div>
            <Link component='button' onClick={onClose}>
              <FontAwesomeIcon
                icon={faXmark}
                color={
                  joyUiTheme.colorSchemes.light.palette[variant][
                    variant === 'warning' ? 500 : 600
                  ]
                }
              />
            </Link>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Message;
