import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  useTheme,
} from '@mui/joy';
import * as Yup from 'yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { JoyDatePicker } from '../../../components/date-picker/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ErrorMessagePopup from '../../../components/error-message/error-message-popup/error-message-popup';
import { useMutation } from 'react-query';
import { CreateProjectReleaseInput } from '../../../types/project';
import projectService from '../../../service/project';
import useAuth from '../../../util/auth-hook';
import { useTranslation } from 'react-i18next';

export type CreateReleaseModalProps = {
  isOpen: boolean;
  onClose: (created: boolean) => void;
  projectId: number;
};

type CreateReleaseFormValues = {
  versionName: string;
  scheduledReleaseDate: Dayjs;
};

const CreateReleaseModal = ({
  isOpen,
  onClose,
  projectId,
}: CreateReleaseModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth();

  const validationSchema = Yup.object().shape({
    versionName: Yup.string()
      .required(t('create-release.errors.version-id.required'))
      .max(15, t('create-release.errors.version-id.length', { max: 15 })),
    scheduledReleaseDate: Yup.mixed<Dayjs>()
      .transform((value) => (dayjs.isDayjs(value) ? value : dayjs(value)))
      .required(t('create-release.errors.sched-rel-date.required'))
      .test({
        name: 'is-dayjs',
        message: t('create-release.errors.sched-rel-date.invalid'),
        test: (value) => {
          return value && dayjs.isDayjs(value) && value.isValid();
        },
      })
      .test({
        name: 'is-in-future',
        message: t('create-release.errors.sched-rel-date.past'),
        test: (value) => {
          if (!value || !dayjs.isDayjs(value)) {
            return false;
          }
          const today = dayjs().startOf('day');
          const selectedDate = value.startOf('day');
          return selectedDate.isSame(today) || selectedDate.isAfter(today);
        },
      }),
  });

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateReleaseFormValues>({
    defaultValues: {
      versionName: undefined,
      scheduledReleaseDate: dayjs(),
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    isLoading: isCreateReleaseLoading,
    isError: isCreateReleaseError,
    error: createReleaseError,
    mutate: submitCreateRelease,
    reset: resetCreateReleaseRequest,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { versionName, scheduledReleaseDate } = getValues();

      const releaseInput: CreateProjectReleaseInput = {
        versionName,
        scheduledReleaseDate: scheduledReleaseDate.toISOString(),
      };

      return await projectService.createRelease(
        accessToken,
        projectId,
        releaseInput,
      );
    },
    {
      onSuccess: () => {
        closeModal(true);
      },
    },
  );

  const closeModal = (created: boolean) => {
    reset();
    onClose(created);
    resetCreateReleaseRequest();
  };

  return (
    <Modal open={isOpen} onClose={() => closeModal(false)}>
      <>
        <ErrorMessagePopup
          isError={isCreateReleaseError}
          error={createReleaseError}
        />
        <ModalDialog minWidth={'400px'}>
          <ModalClose />
          <DialogTitle level='h2'>{t('create-release.title')}</DialogTitle>
          <DialogContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                marginTop: '16px',
              }}
            >
              <FormControl>
                <FormLabel>{t('create-release.version-id')}</FormLabel>
                <Input
                  placeholder='e.g. v1.0.0'
                  {...register('versionName')}
                  error={!!errors.versionName}
                  disabled={isCreateReleaseLoading}
                />
                {!!errors.versionName && (
                  <FormHelperText
                    sx={{ color: theme.vars.palette.danger[500] }}
                  >
                    {errors.versionName.message}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>{t('create-release.sched-rel-date')}</FormLabel>
                <Controller
                  control={control}
                  name='scheduledReleaseDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <JoyDatePicker
                        slotProps={{
                          field: { clearable: true },
                        }}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        disablePast
                        error={!!errors.scheduledReleaseDate}
                        disabled={isCreateReleaseLoading}
                      />
                    );
                  }}
                />

                {!!errors.scheduledReleaseDate && (
                  <FormHelperText
                    sx={{ color: theme.vars.palette.danger[500] }}
                  >
                    {errors.scheduledReleaseDate.message}
                  </FormHelperText>
                )}
              </FormControl>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '8px',
                }}
              >
                <Button
                  startDecorator={<FontAwesomeIcon icon={faCheck} />}
                  disabled={!isValid}
                  loading={isCreateReleaseLoading}
                  onClick={() => submitCreateRelease()}
                >
                  {t('common.create')}
                </Button>
              </div>
            </div>
          </DialogContent>
        </ModalDialog>
      </>
    </Modal>
  );
};

export default CreateReleaseModal;
