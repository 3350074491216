import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from '@mui/joy';
import React from 'react';
import { LanguageData } from '../../types/misc';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQueryClient } from 'react-query';
import useAuth from '../../util/auth-hook';
import translationService from '../../service/translation';
import ErrorMessage from '../error-message/error-message';
import { AxiosError } from 'axios';

export type AutoTranslationConfirmationModalProps = {
  isOpen: boolean;
  lang?: LanguageData;
  releaseId?: string;
  onClose: (hasStatusChanged: boolean) => void;
};

const AutoTranslationConfirmationModal = ({
  isOpen,
  lang,
  releaseId,
  onClose,
}: AutoTranslationConfirmationModalProps) => {
  const { getAccessTokenSilently } = useAuth();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    isLoading: isBulkAutoTranslateLoading,
    isError: isBulkAutoTranslateError,
    error: bulkAutoTranslateError,
    mutate: submitBulkAutoTranslate,
    reset: resetBulkAutoTranslate,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await translationService.autoTranslateLangInRelease(
        accessToken,
        Number(releaseId),
        lang?.code as string,
      );
    },
    {
      onSuccess: (response) => {
        handleClose(true, response.hasStatusChanged);
      },
    },
  );

  const failedKeys = React.useMemo(() => {
    const apiError =
      bulkAutoTranslateError instanceof AxiosError
        ? bulkAutoTranslateError.response?.data?.error
        : undefined;

    if (
      apiError?.code === 'ERR_TRNS_BAUTO_FAIL' &&
      apiError?.detail?.failedKeys &&
      Array.isArray(apiError?.detail?.failedKeys) &&
      apiError?.detail?.failedKeys.length > 0
    ) {
      return apiError?.detail?.failedKeys as string[];
    }
  }, [bulkAutoTranslateError]);

  const handleClose = (wasSubmitted: boolean, hasStatusChanged?: boolean) => {
    onClose(hasStatusChanged ?? false);
    resetBulkAutoTranslate();
    if (wasSubmitted) {
        queryClient.invalidateQueries(['translations', Number(releaseId), lang?.code]);
        queryClient.invalidateQueries(['language-translation-stats', releaseId]);
    }
  };

  return (
    <Modal open={isOpen && !!lang} onClose={() => !isBulkAutoTranslateLoading ? handleClose(false) : undefined}>
      <ModalDialog minWidth={'450px'} maxWidth={'450px'}>
        <DialogTitle>
          {t('translate.auto-translate')} {lang?.name}
        </DialogTitle>
        {!isBulkAutoTranslateLoading && <ModalClose />}
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div>
              <Trans
                i18nKey='translate.bulk-auto.confirm'
                components={{ b: <b /> }}
                values={{ langName: lang?.name }}
              />
            </div>
            <div style={{ fontSize: '14px' }}>
              {t('translate.bulk-auto.info')}
            </div>
            <ErrorMessage
              isError={isBulkAutoTranslateError}
              error={bulkAutoTranslateError}
              retry={submitBulkAutoTranslate}
            >
              {failedKeys && (
                <>
                  <div>{t('translate.bulk-auto.error')}</div>
                  <ul>
                    {failedKeys.map((failedKey) => (
                      <li key={failedKey}>{failedKey}</li>
                    ))}
                  </ul>
                </>
              )}
            </ErrorMessage>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '8px',
              }}
            >
              <Button startDecorator={<FontAwesomeIcon icon={faCheck} />} loading={isBulkAutoTranslateLoading} onClick={() => submitBulkAutoTranslate()}>
                {t('common.confirm')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default AutoTranslationConfirmationModal;
