import React from 'react';
import Heading from '../../components/heading/heading';
import { faAngleLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page } from '../../types/navigation';
import { Button, Link, Typography } from '@mui/joy';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import HelpBox, { HelpItem } from '../../components/help-box/help-box';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import useAuth from '../../util/auth-hook';
import projectService from '../../service/project';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import LoadingMessage from '../../components/loading-message/loading-message';
import generalUtil from '../../util/general';
import KeysTable from '../../components/keys-table/keys-table';
import SavingIndicator, {
  SavingStatus,
} from '../../components/saving-indicator/saving-indicator';
import { AppRole, ProjectRole } from '../../types/user';
import { ReleaseStatus } from '../../types/project';
import ApproveReleaseConfirmationModal from '../../components/approve-release-confirmation-modal/approve-release-confirmation-modal';
import KeyFormatModal from '../../components/key-format-modal/key-format-modal';
import { useTranslation } from 'react-i18next';

const KeysScreen = () => {
  const { projectId, releaseId } = useParams();
  const { getAccessTokenSilently, userPerms } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [savingStatus, setSavingStatus] =
    React.useState<SavingStatus>('hidden');
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] =
    React.useState(false);
  const [keyCount, setKeyCount] = React.useState(0);
  const [showKeyFormatModal, setShowKeyFormatModal] = React.useState(false);
  const [allKeysReviewed, setAllKeysReviewed] = React.useState(false);

  const setSaving = (status: SavingStatus) => {
    setSavingStatus(status);
  };

  const updateKeyCount = (count: number) => {
    setKeyCount(count);
  };

  const updateAllKeysReviewedStatus = (allReviewed: boolean) => {
    setAllKeysReviewed(allReviewed);
  };

  const {
    data: basicReleaseDetails,
    isFetching: isBasicReleaseDetailsFetching,
    isError: isBasicReleaseDetailsError,
    error: basicReleaseDetailsError,
    refetch: refetchBasicReleaseDetails,
  } = useQuery(
    ['basic-release-details', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getBasicReleaseDetails(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isApproveLoading,
    isError: isApproveError,
    error: approveError,
    mutate: submitApproval,
    reset: resetSubmitApproval,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await projectService.approveRelease(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['project-details', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['basic-release-details', releaseId],
        });
        navigate(`${Page.ProjectRoute}/${projectId}`);
      },
    },
  );

  const showApproveButton = React.useMemo(() => {
    if (!basicReleaseDetails) {
      return false;
    }

    const { status, userProjectRole } = basicReleaseDetails;

    if (status.code === ReleaseStatus.InDevelopment) {
      return (
        userProjectRole === ProjectRole.ProjectManager ||
        userProjectRole === ProjectRole.Admin ||
        userPerms?.includes(AppRole.Admin)
      );
    }

    if (status.code === ReleaseStatus.ClientReviewPreTranslation) {
      return (
        userProjectRole === ProjectRole.TranslationCoordinator ||
        userProjectRole === ProjectRole.Admin ||
        userPerms?.includes(AppRole.Admin)
      );
    }

    return false;
  }, [basicReleaseDetails, userPerms]);

  const keysEditable = React.useMemo(() => {
    if (!basicReleaseDetails) {
      return false;
    }

    const { status, userProjectRole } = basicReleaseDetails;

    const isAdmin = userPerms?.includes(AppRole.Admin) ?? false;

    if (status.code === ReleaseStatus.InDevelopment) {
      return (
        userProjectRole === ProjectRole.Admin ||
        userProjectRole === ProjectRole.Developer ||
        isAdmin
      );
    }

    if (status.code === ReleaseStatus.ClientReviewPreTranslation) {
      return (
        userProjectRole === ProjectRole.Admin ||
        userProjectRole === ProjectRole.TranslationCoordinator ||
        isAdmin
      );
    }

    return false;
  }, [basicReleaseDetails, userPerms]);

  const showReview = React.useMemo(() => {
    if (!basicReleaseDetails) {
      return false;
    }

    const { status, userProjectRole } = basicReleaseDetails;

    const isAdmin = userPerms?.includes(AppRole.Admin) ?? false;

    if (status.code === ReleaseStatus.ClientReviewPreTranslation) {
      return (
        userProjectRole === ProjectRole.Admin ||
        userProjectRole === ProjectRole.TranslationCoordinator ||
        isAdmin
      );
    }

    return false;
  }, [basicReleaseDetails, userPerms]);

  const handleApprovalCancel = () => {
    setIsApproveConfirmationOpen(false);
    resetSubmitApproval();
  };

  const helpItems: HelpItem[] = React.useMemo(() => {
    return [
      {
        title: t('keys-table.headings.key'),
        text: t('keys.help.key.text'),
        link: t('keys.help.key.link'),
        onLinkClick: () => setShowKeyFormatModal(true),
      },
      {
        title: t('keys-table.headings.primary-value'),
        text: t('keys.help.primary-value'),
      },
      {
        title: t('keys-table.headings.placeholder'),
        text: t('keys.help.placeholder'),
      },
      {
        title: t('keys-table.headings.context'),
        text: t('keys.help.context'),
      },
      {
        title: t('keys.help.key-limit.title'),
        text: t('keys.help.key-limit.text', { num: 500 })
      }
    ];
  }, [t]);

  return (
    <>
      <KeyFormatModal
        isOpen={showKeyFormatModal}
        onClose={() => setShowKeyFormatModal(false)}
      />
      <ApproveReleaseConfirmationModal
        isOpen={isApproveConfirmationOpen}
        handleCancel={handleApprovalCancel}
        handleConfirm={submitApproval}
        releaseName={basicReleaseDetails?.versionName}
        status={basicReleaseDetails?.status}
        isLoading={isApproveLoading}
        isError={isApproveError}
        error={approveError}
        allReviewed={allKeysReviewed}
      />
      <LoadingMessage isLoading={isBasicReleaseDetailsFetching} />
      <ErrorMessagePopup
        isError={isBasicReleaseDetailsError}
        error={basicReleaseDetailsError}
        retry={refetchBasicReleaseDetails}
      />

      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        component={RouterLink}
        to={`${Page.ProjectRoute}/${projectId}`}
      >
        {t('page-title.project-details')}
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>
        {t('page-title.add-keys')}
      </Heading>
      <div style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '24px' }}>
          <div>
            <Typography fontSize='14px'>
              {(basicReleaseDetails?.userProjectRole === ProjectRole.Admin ||
                userPerms?.includes(AppRole.Admin)) &&
                (basicReleaseDetails?.status.code ===
                ReleaseStatus.InDevelopment
                  ? t('keys.intro-text.indev-admin')
                  : basicReleaseDetails?.status.code ===
                      ReleaseStatus.ClientReviewPreTranslation
                    ? t('keys.intro-text.crpre-admin')
                    : t('keys.intro-text.review-only'))}
              {basicReleaseDetails?.userProjectRole === ProjectRole.Developer &&
                (basicReleaseDetails.status.code === ReleaseStatus.InDevelopment
                  ? t('keys.intro-text.indev-devlp')
                  : t('keys.intro-text.review-only'))}
              {basicReleaseDetails?.userProjectRole ===
                ProjectRole.ProjectManager &&
                (basicReleaseDetails.status.code === ReleaseStatus.InDevelopment
                  ? t('keys.intro-text.indev-prjmn')
                  : t('keys.intro-text.review-only'))}
              {basicReleaseDetails?.userProjectRole ===
                ProjectRole.TranslationCoordinator &&
                (basicReleaseDetails.status.code === ReleaseStatus.InDevelopment
                  ? t('keys.intro-text.indev-trnco')
                  : t('keys.intro-text.review-trnco'))}
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
            <SavingIndicator status={savingStatus} />
            {showApproveButton && (
              <Button
                color='success'
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
                onClick={() => setIsApproveConfirmationOpen(true)}
                disabled={keyCount === 0}
              >
                {t('common.approve')}
              </Button>
            )}
          </div>
        </div>
        <BasicReleaseDetails
          projectName={generalUtil.formatData(basicReleaseDetails?.projectName)}
          versionName={generalUtil.formatData(basicReleaseDetails?.versionName)}
          isLoading={isBasicReleaseDetailsFetching}
        />
      </div>
      <div style={{ display: 'flex', gap: '24px' }}>
        <div style={{ width: '75%' }}>
          <KeysTable
            releaseId={Number(releaseId)}
            releaseName={basicReleaseDetails?.versionName}
            showReview={showReview}
            setSavingStatus={setSaving}
            updateKeyCount={updateKeyCount}
            updateAllReviewedStatus={updateAllKeysReviewedStatus}
            openKeyFormatModal={() => setShowKeyFormatModal(true)}
            isLoading={isBasicReleaseDetailsFetching}
            editable={keysEditable}
          />
        </div>
        <div
          style={{ display: 'flex', flexGrow: 1, maxWidth: 'calc(25% - 24px)' }}
        >
          <HelpBox items={helpItems} />
        </div>
      </div>
    </>
  );
};

export default KeysScreen;
