import React from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import LanguageSelectionModal from './language-selection-modal/language-selection-modal';
import useAuth from '../../util/auth-hook';
import projectService from '../../service/project';
import { useMutation, useQuery } from 'react-query';
import { Language, LanguageData, supportedLanguages } from '../../types/misc';
import { Page } from '../../types/navigation';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { Button, Link, Typography } from '@mui/joy';
import { faAngleLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heading from '../../components/heading/heading';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import generalUtil from '../../util/general';
import TranslateComponent from '../../components/translate/translate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import translationService from '../../service/translation';
import miscService from '../../service/misc';
import {
  mapFormValuesToApiData,
  translateFormValidationSchema,
  TranslationFormValues,
} from './translate.helper';
import { useTranslation } from 'react-i18next';
import UnsavedChangesWarningModal from '../../components/unsaved-changes-warning-modal/unsaved-changes-warning-modal';
import ReactRouterPrompt from 'react-router-prompt';

const TranslateScreen = () => {
  const { projectId, releaseId, lang } = useParams();
  const { getAccessTokenSilently } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [isLanguageSelectionModalOpen, setIsLanguageSelectionModalOpen] =
    React.useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = React.useState(false);
  const [pendingNavigation, setPendingNavigation] = React.useState<
    string | undefined
  >();

  const form = useForm<TranslationFormValues>({
    resolver: yupResolver(translateFormValidationSchema),
    mode: 'all',
  });

  const {
    getValues,
    formState: { isValid },
  } = form;

  React.useEffect(() => {
    setIsLanguageSelectionModalOpen(!lang);
  }, [lang]);

  const {
    data: basicReleaseDetails,
    isFetching: isBasicReleaseDetailsLoading,
    isError: isBasicReleaseDetailsError,
    error: basicReleaseDetailsError,
    refetch: refetchBasicReleaseDetails,
  } = useQuery(
    ['basic-release-details', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getBasicReleaseDetails(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: existingTranslations,
    isFetching: isExistingTranslationsLoading,
    isError: isExistingTranslationsError,
    error: existingTranslationsError,
    refetch: refetchExistingTranslations,
  } = useQuery(
    ['existing-translations', releaseId, lang],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getTranslationValues(
        accessToken,
        Number(releaseId),
        lang as string,
      );
    },
    {
      enabled: false,
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isError: isLanguagesError,
    error: languagesError,
    refetch: refetchLanguages,
  } = useQuery(
    'languages',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLanguages(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isSaveTranslationsLoading,
    isError: isSaveTranslationsError,
    error: saveTranslationsError,
    mutate: saveTranslations,
  } = useMutation(
    async (callback?: () => void) => {
      const accessToken = await getAccessTokenSilently();

      return await translationService.updateTranslationValues(
        accessToken,
        Number(releaseId),
        lang as Language,
        mapFormValuesToApiData(getValues()),
      );
    },
    {
      onSuccess: (response, callback) => {
        setIsUnsavedChanges(false);
        if (callback) {
          callback();
        } else {
          const queryParam = response.hasStatusChanged
            ? '?statusChanged=true'
            : '';
          setPendingNavigation(
            `${Page.ProjectRoute}/${projectId}${queryParam}`,
          );
        }
      },
    },
  );

  React.useEffect(() => {
    if (!isUnsavedChanges && pendingNavigation) {
      navigate(pendingNavigation);
    }
  }, [isUnsavedChanges, pendingNavigation, navigate]);

  const {
    data: untranslatedLangs,
    isFetching: isUntranslatedLangsLoading,
    isError: isUntranslatedLangsError,
    error: untranslatedLangsError,
    refetch: refetchUntranslatedLangs,
  } = useQuery(
    ['untranslated-langs', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getUntranslatedLangs(
        accessToken,
        Number(releaseId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const isLoading = React.useMemo(() => {
    return (
      isBasicReleaseDetailsLoading ||
      isExistingTranslationsLoading ||
      isLanguagesFetching ||
      isSaveTranslationsLoading ||
      isUntranslatedLangsLoading ||
      isProjectDetailsFetching
    );
  }, [
    isBasicReleaseDetailsLoading,
    isExistingTranslationsLoading,
    isLanguagesFetching,
    isSaveTranslationsLoading,
    isUntranslatedLangsLoading,
    isProjectDetailsFetching,
  ]);

  const translatingInto: LanguageData | undefined = React.useMemo(() => {
    if (languages && lang) {
      return {
        code: lang,
        name:
          languages.find((langData) => langData.code === lang)?.name ??
          'common.unknown',
      };
    }
  }, [lang, languages]);

  // redirects user back to select language modal if language is not valid language, or is not supported by the project
  React.useEffect(() => {
    if (lang && untranslatedLangs) {
      if (
        !supportedLanguages.includes(lang) ||
        !untranslatedLangs?.map((lng) => lng.code).includes(lang)
      ) {
        navigate(
          `${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.Translate}`,
        );
      } else {
        refetchExistingTranslations();
      }
    }
  }, [
    lang,
    untranslatedLangs,
    navigate,
    projectId,
    releaseId,
    refetchExistingTranslations,
  ]);

  const handleUnsavedChangesSaveAndClose = (onConfirm: () => void) => {
    saveTranslations(onConfirm);
  };

  return (
    <>
      <ReactRouterPrompt when={isUnsavedChanges || isSaveTranslationsLoading}>
        {({ isActive, onConfirm, onCancel }) => (
          <UnsavedChangesWarningModal
            isOpen={isActive}
            isLoading={isSaveTranslationsLoading}
            handleStayHere={onCancel}
            handleCloseWithoutSaving={onConfirm}
            handleSaveAndClose={() =>
              handleUnsavedChangesSaveAndClose(onConfirm)
            }
          />
        )}
      </ReactRouterPrompt>
      <LanguageSelectionModal
        isOpen={isLanguageSelectionModalOpen}
        releaseId={Number(releaseId)}
        projectId={Number(projectId)}
        untranslatedLangs={untranslatedLangs}
        isLoading={isUntranslatedLangsLoading}
        isError={isUntranslatedLangsError}
        untranslatedLangsError={untranslatedLangsError}
        refetchUntranslatedLangs={refetchUntranslatedLangs}
      />
      <LoadingMessage isLoading={isLoading} />
      <ErrorMessagePopup
        isError={isExistingTranslationsError}
        error={existingTranslationsError}
        retry={refetchExistingTranslations}
      />
      <ErrorMessagePopup
        isError={isLanguagesError}
        error={languagesError}
        retry={refetchLanguages}
      />
      <ErrorMessagePopup
        isError={isBasicReleaseDetailsError}
        error={basicReleaseDetailsError}
        retry={refetchBasicReleaseDetails}
      />
      <ErrorMessagePopup
        isError={isSaveTranslationsError}
        error={saveTranslationsError}
        retry={() => saveTranslations(undefined)}
      />
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        component={RouterLink}
        to={`${Page.ProjectRoute}/${projectId}`}
      >
        {t('page-title.project-details')}
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>
        {t('page-title.translate')}
      </Heading>
      <div style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '24px' }}>
          <div>
            <Typography fontSize='14px'>{t('translate.intro-text')}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              startDecorator={<FontAwesomeIcon icon={faFloppyDisk} />}
              onClick={() => saveTranslations(undefined)}
              disabled={!isValid || !isUnsavedChanges}
              loading={isSaveTranslationsLoading}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
        <BasicReleaseDetails
          projectName={generalUtil.formatData(basicReleaseDetails?.projectName)}
          versionName={generalUtil.formatData(basicReleaseDetails?.versionName)}
          isLoading={isBasicReleaseDetailsLoading}
        />
      </div>
      <div style={{ width: '75%' }}>
        <TranslateComponent
          lang={translatingInto}
          defaultLang={projectDetails?.defaultLang.code}
          form={form}
          isLoading={
            isLoading ||
            isUntranslatedLangsError ||
            isBasicReleaseDetailsError ||
            isExistingTranslationsError
          }
          existingTranslations={existingTranslations}
          onEdit={() => {
            if (!isUnsavedChanges) {
              setIsUnsavedChanges(true);
            }
          }}
        />
      </div>
    </>
  );
};

export default TranslateScreen;
