import { ProjectReleaseStatusData, ReleaseStatus } from "../../types/project";

export const getNextReleaseStatusData = (currentStatus?: ReleaseStatus): ProjectReleaseStatusData | undefined => {
    switch(currentStatus) {
        case ReleaseStatus.InDevelopment: {
            return {
                code: ReleaseStatus.ClientReviewPreTranslation,
                description: 'release-status.crpre.long'
            }
        }
        case ReleaseStatus.ClientReviewPreTranslation: {
            return {
                code: ReleaseStatus.Translating,
                description: 'release-status.trans'
            }
        }
        case ReleaseStatus.Translating: {
            return {
                code: ReleaseStatus.ClientReviewPostTranslation,
                description: 'release-status.crpos.long'
            }
        }
        default: {
            return undefined;
        }
    }
}