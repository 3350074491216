import { StyleSheet } from "aphrodite";
import theme from "../../../styles/theme";

const footerStyles = StyleSheet.create({
    container: {
        backgroundColor: theme.colour.primary,
        height: '150px',
        padding: '54px',
        display: 'flex',
        alignItems: 'center'
    }
});

export default footerStyles;