import React from 'react';
import Card from '../card/card';
import { css } from 'aphrodite';
import projectDetailsStyles from '../../pages/project-details/project-details.styles';
import { LanguageData } from '../../types/misc';
import CountryFlag from '../country-flag/country-flag';
import { Skeleton } from '@mui/joy';
import theme from '../../styles/theme';
import { useTranslation } from 'react-i18next';

export type LanguageListProps = {
  langs?: LanguageData[];
  defaultLang?: LanguageData;
};

const LanguageList = ({ langs, defaultLang }: LanguageListProps) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [showTopGradient, setShowTopGradient] = React.useState(false);
  const [showBottomGradient, setShowBottomGradient] = React.useState(false);

  const allSortedTranslatedLangs = React.useMemo(() => {
    return langs && defaultLang
      ? [
          { ...defaultLang, name: t(defaultLang.name) },
          ...langs
            .map((lang) => ({ ...lang, name: t(lang.name) }))
            .sort((a, b) => (a.name > b.name ? 1 : -1)),
        ]
      : undefined;
  }, [langs, defaultLang, t]);

  React.useEffect(() => {
    const langList = listRef.current;

    const handleScroll = () => {
      if (langs && langs.length > 6 && langList && langList.scrollTop > 0) {
        setShowTopGradient(true);
      } else {
        setShowTopGradient(false);
      }

      if (
        langs &&
        langs.length > 6 &&
        langList &&
        langList.scrollTop + langList.clientHeight < langList.scrollHeight
      ) {
        setShowBottomGradient(true);
      } else {
        setShowBottomGradient(false);
      }
    };

    // run initially
    handleScroll();

    langList?.addEventListener('scroll', handleScroll);

    return () => {
      langList?.removeEventListener('scroll', handleScroll);
    };
  }, [langs]);

  return (
    <Card sx={{ padding: '24px' }}>
      <>
        <h2 style={{ margin: 0 }}>{t('common.languages')}</h2>
        {langs && defaultLang ? (
          <div
            className={css(
              projectDetailsStyles.languagesListContainer,
              showTopGradient &&
                projectDetailsStyles.usersListContainerTopGradient,
              showBottomGradient &&
                projectDetailsStyles.usersListContainerBottomGradient,
            )}
            ref={listRef}
          >
            {allSortedTranslatedLangs?.map((lang) => (
              <div style={{ display: 'flex', gap: '8px' }} key={lang.code}>
                <CountryFlag languageCode={lang.code} size='24px' />
                <div
                  style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                >
                  <div>{lang.name}</div>
                  {lang.code === defaultLang.code && (
                    <div style={{ fontSize: '14px', color: theme.colour.grey }}>
                      {t('language-list.default')}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'24px'}
              loading
              sx={{ position: 'relative' }}
            />
          </div>
        )}
      </>
    </Card>
  );
};

export default LanguageList;
