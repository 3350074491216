import React from 'react';
import Card from '../card/card';
import { css } from 'aphrodite';
import projectDetailsStyles from '../../pages/project-details/project-details.styles';
import ProfileName from '../profile-name/profile-name';
import { ProjectUserData } from '../../pages/project-details/project-details';
import { Skeleton } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export type ProjectTeamProps = {
  projectUserData?: ProjectUserData[];
};

const ProjectTeam = ({ projectUserData }: ProjectTeamProps) => {
  const userListRef = React.useRef<HTMLDivElement>(null);

  const [showTopGradient, setShowTopGradient] = React.useState(false);
  const [showBottomGradient, setShowBottomGradient] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    const userList = userListRef.current;

    const handleScroll = () => {
      if (
        projectUserData &&
        projectUserData.length > 6 &&
        userList &&
        userList.scrollTop > 0
      ) {
        setShowTopGradient(true);
      } else {
        setShowTopGradient(false);
      }

      if (
        projectUserData &&
        projectUserData.length > 6 &&
        userList &&
        userList.scrollTop + userList.clientHeight < userList.scrollHeight
      ) {
        setShowBottomGradient(true);
      } else {
        setShowBottomGradient(false);
      }
    };

    // run initially
    handleScroll();

    userList?.addEventListener('scroll', handleScroll);

    return () => {
      userList?.removeEventListener('scroll', handleScroll);
    };
  }, [projectUserData]);

  return (
    <Card sx={{ padding: '24px' }}>
      <>
        <h2 style={{ margin: 0 }}>{t('project-team.title')}</h2>
        {projectUserData ? (
          <div
            className={css(
              projectDetailsStyles.usersListContainer,
              showTopGradient &&
                projectDetailsStyles.usersListContainerTopGradient,
              showBottomGradient &&
                projectDetailsStyles.usersListContainerBottomGradient,
            )}
            ref={userListRef}
          >
            {projectUserData?.map((user) => (
              <ProfileName
                key={user.user.id}
                user={user.user}
                tagline={user.role?.description ? t(user.role?.description) : undefined}
              />
            ))}
          </div>
        ) : (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            data-testid='project-team-loaders'
          >
            <Skeleton
              width={'100%'}
              height={'45px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'45px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'45px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'45px'}
              loading
              sx={{ position: 'relative' }}
            />
            <Skeleton
              width={'100%'}
              height={'45px'}
              loading
              sx={{ position: 'relative' }}
            />
          </div>
        )}
      </>
    </Card>
  );
};

export default ProjectTeam;
