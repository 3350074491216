const theme = {
    colour: {
        black: '#000000',
        white: '#FFFFFF',
        primary: '#476C9B',
        background: '#F0F0F0',
        grey: '#757575',
        xLightGrey: '#F2F2F2',
        lightGrey: '#D5D5D5',
        darkGrey: '#454545'
    }
}

export default theme;