import React from 'react';
import { Language, LanguageData } from '../../types/misc';
import Card from '../card/card';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { css } from 'aphrodite';
import keysTableStyles from '../keys-table/keys-table.styles';
import useAuth from '../../util/auth-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import translationService from '../../service/translation';
import LoadingMessage from '../loading-message/loading-message';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import ProfileName from '../profile-name/profile-name';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil } from '@fortawesome/free-solid-svg-icons';
import EditTranslationModal from '../edit-translation-modal/edit-translation-modal';
import {
  TranslationData,
  UpdateTranslationValueResponse,
} from '../../types/translation';
import AutoTranslatedIndicator from '../auto-translated-indicator/auto-translated-indicator';
import translationsTableStyles from './translations-table.styles';
import theme from '../../styles/theme';
import generalUtil from '../../util/general';
import userService from '../../service/user';
import authUtil from '../../util/auth';
import { useTranslation } from 'react-i18next';

export type TranslationsTableProps = {
  language: LanguageData;
  defaultLanguage?: Language;
  releaseId: number;
  editable: boolean;
  showReview: boolean;
  highlightUntranslated?: boolean;
  onTranslationUpdate: (statusChange: boolean) => void;
  onReview?: () => void;
};

type ReviewKeyInput = {
  reviewed: boolean;
  key?: string;
  keyIndex?: number;
};

const TranslationsTable = ({
  language,
  defaultLanguage,
  releaseId,
  editable,
  showReview,
  highlightUntranslated,
  onTranslationUpdate,
  onReview,
}: TranslationsTableProps) => {
  const { getAccessTokenSilently, user } = useAuth();

  const [rowEditing, setRowEditing] = React.useState<number | undefined>();
  const [allReviewed, setAllReviewed] = React.useState(false);
  // number = key index | null = all reviewing | undefined = none reviewing
  const [keyReviewing, setKeyReviewing] = React.useState<
    number | null | undefined
  >(undefined);
  const [rows, setRows] = React.useState<TranslationData[]>([]);

  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const joyUiTheme = useTheme();

  const {
    data: translations,
    isFetching: isTranslationsFetching,
    isError: isTranslationsError,
    error: translationsError,
    refetch: refetchTranslations,
  } = useQuery(
    ['translations', releaseId, language.code],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getTranslationValues(
        accessToken,
        Number(releaseId),
        language.code,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  React.useEffect(() => {
    setRows(translations ?? []);
  }, [translations]);

  const {
    data: releaseParticipantsUserData,
    isFetching: isReleaseParticipantsFetching,
    isError: isReleaseParticipantsError,
    error: releaseParticipantsError,
    refetch: refetchReleaseParticipants,
  } = useQuery(
    ['release-participants', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const res = await userService.getReleaseParticipants(
        accessToken,
        Number(releaseId),
      );

      return user ? [...res, authUtil.getCurrentUserObject(user)] : res;
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    isLoading: isReviewKeyLoading,
    isError: isReviewKeyError,
    error: reviewKeyError,
    mutate: reviewKey,
  } = useMutation(
    async (input: ReviewKeyInput) => {
      const accessToken = await getAccessTokenSilently();

      setKeyReviewing(input.keyIndex ?? null);

      return await translationService.reviewTranslation(
        accessToken,
        Number(releaseId),
        input.reviewed,
        language.code,
        input.key,
      );
    },
    {
      onError: () => {
        setKeyReviewing(undefined);
      },
      onSuccess: (_response, input) => {
        const updatedRowData = rows.map((exstData) => {
          if (!input.key || exstData.key === input.key) {
            return {
              ...exstData,
              review: input.reviewed
                ? {
                    user: user?.sub ?? 'unknown',
                    timestamp: new Date().toISOString(),
                  }
                : undefined,
            };
          } else {
            return exstData;
          }
        });
        setRows(updatedRowData);
        queryClient.setQueryData(
          ['translations', releaseId, language.code],
          updatedRowData,
        );

        if (!input.key) {
          setAllReviewed(input.reviewed);
        }

        if (onReview) {
          onReview();
        }

        setKeyReviewing(undefined);
      },
    },
  );

  const editRow = (index?: number) => {
    setRowEditing(index);
  };

  const handleTranslationUpdate = (
    response: UpdateTranslationValueResponse,
  ) => {
    setRows(response.values);
    queryClient.setQueryData(
      ['translations', releaseId, language.code],
      response.values,
    );
    onTranslationUpdate(response.hasStatusChanged);
  };

  React.useEffect(() => {
    setAllReviewed(
      rows.length > 0 && rows?.every((keyData) => !!keyData.review),
    );
  }, [rows]);

  const translationDataEditing = React.useMemo(() => {
    if (rows && rowEditing !== undefined) {
      return rows[rowEditing];
    }
  }, [rows, rowEditing]);

  return (
    <>
      <LoadingMessage
        isLoading={isTranslationsFetching || isReleaseParticipantsFetching}
      />
      <ErrorMessagePopup
        isError={isTranslationsError}
        error={translationsError}
        retry={refetchTranslations}
      />
      <ErrorMessagePopup
        isError={isReleaseParticipantsError}
        error={releaseParticipantsError}
        retry={refetchReleaseParticipants}
      />
      <ErrorMessagePopup isError={isReviewKeyError} error={reviewKeyError} />
      <EditTranslationModal
        isOpen={rowEditing !== undefined}
        lang={language}
        defaultLang={defaultLanguage}
        releaseId={releaseId}
        translationData={translationDataEditing}
        onClose={() => setRowEditing(undefined)}
        onTranslationUpdate={handleTranslationUpdate}
      />
      <Card data-testid={`translations-table-${language.code}`}>
        <Table>
          <thead>
            <tr data-testid='translations-table-heading'>
              {showReview && (
                <th
                  className={css(keysTableStyles.heading)}
                  style={{ width: '3%' }}
                >
                  <Tooltip
                    title={
                      allReviewed
                        ? t('keys-table.mark-all-unreviewed')
                        : t('keys-table.mark-all-reviewed')
                    }
                    placement='top'
                    arrow
                    enterDelay={500}
                  >
                    {isReviewKeyLoading && keyReviewing === null ? (
                      <CircularProgress
                        size='sm'
                        sx={{ '--CircularProgress-size': '20px' }}
                      />
                    ) : (
                      <Checkbox
                        checked={allReviewed}
                        onChange={(event) => {
                          reviewKey({ reviewed: event.target.checked });
                        }}
                      />
                    )}
                  </Tooltip>
                </th>
              )}
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '14%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.key')}
                  <Tooltip
                    title={t('translations-table.key-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-key'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: editable ? '20%' : '22%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.primary-value')}
                  <Tooltip
                    title={t('translations-table.primary-value-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-primaryvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: editable ? '23%' : '25%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t(language.name)} {t('translations-table.translated-value')}
                  <Tooltip
                    title={t('translations-table.translated-value-tooltip', {
                      lang: t(language.name),
                    })}
                    placement='top'
                    arrow
                    sx={{ maxWidth: '400px' }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translatedvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '8%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.placeholder')}
                  <Tooltip
                    title={t('translations-table.placeholder-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-placeholder'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '15%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('keys-table.headings.context')}
                  <Tooltip
                    title={t('translations-table.context-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-context'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '13%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {t('translations-table.last-modified')}
                  <Tooltip
                    title={t('translations-table.last-modified-tooltip')}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translator'
                    />
                  </Tooltip>
                </div>
              </th>
              {editable && (
                <th
                  className={css(keysTableStyles.heading)}
                  style={{ width: '4%' }}
                ></th>
              )}
            </tr>
          </thead>
          <tbody>
            {isTranslationsFetching || isReleaseParticipantsFetching ? (
              <tr>
                <td colSpan={8}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    {(rows.length > 0
                      ? rows
                      : [
                          { key: 'loader-1' },
                          { key: 'loader-2' },
                          { key: 'loader-3' },
                          { key: 'loader-4' },
                        ]
                    ).map((data, index) => (
                      <Skeleton
                        key={`${data.key}-${index}`}
                        variant='rectangular'
                        height={'50px'}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            ) : (
              rows?.map((data, index) => (
                <tr
                  key={`${data.id}-${data.key}`}
                  data-testid={`translation-table-row-${data.id}`}
                  style={{ backgroundColor: highlightUntranslated && !data.translatedValue ? joyUiTheme.colorSchemes.light.palette.warning[200] : undefined }}
                >
                  {showReview && (
                    <td data-testid={`translation-table-row-${data.id}-review`}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip
                          title={
                            <>
                              {data.review ? (
                                <div style={{ textAlign: 'center' }}>
                                  <div>{t('keys-table.mark-unreviewed')}</div>
                                  <div>
                                    <i>
                                      {t('keys-table.reviewed-by')}{' '}
                                      {
                                        releaseParticipantsUserData?.find(
                                          (usr) => usr.id === data.review?.user,
                                        )?.name.full
                                      }{' '}
                                      {generalUtil.getTimestampRelativeToNow(
                                        data.review.timestamp,
                                        i18n.language,
                                      )}
                                    </i>
                                  </div>
                                </div>
                              ) : (
                                <div>{t('keys-table.mark-reviewed')}</div>
                              )}
                            </>
                          }
                          placement='top'
                          arrow
                          enterDelay={500}
                        >
                          {isReviewKeyLoading && keyReviewing === index ? (
                            <CircularProgress
                              size='sm'
                              sx={{ '--CircularProgress-size': '20px' }}
                            />
                          ) : (
                            <Checkbox
                              checked={!!data.review}
                              defaultChecked={!!data.review}
                              disabled={isReviewKeyLoading}
                              onChange={(e) => {
                                reviewKey({
                                  reviewed: e.target.checked,
                                  key: data.key,
                                  keyIndex: index,
                                });
                              }}
                            />
                          )}
                        </Tooltip>
                      </div>
                    </td>
                  )}
                  <td>
                    <div style={{ display: 'flex' }}>
                      <Typography
                        fontFamily={'monospace'}
                        variant='soft'
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        {data.key}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div style={{ overflowWrap: 'anywhere' }}>{data.primaryValue}</div>
                  </td>
                  <td>
                    {data.translatedValue ? (
                      <div style={{ overflowWrap: 'anywhere' }}>{data.translatedValue}</div>
                    ) : (
                      <div
                        style={{
                          color: theme.colour.grey,
                          fontStyle: 'italic',
                        }}
                      >
                        {`( ${t('translations-table.blank')} )`}
                      </div>
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox checked={data.placeholder} disabled />
                    </div>
                  </td>
                  <td>
                    <div style={{ overflowWrap: 'anywhere' }}>{data.context}</div>
                  </td>
                  <td>
                    <div>
                      {data.autoTranslated ? (
                        <AutoTranslatedIndicator
                          initiatorName={
                            releaseParticipantsUserData?.find(
                              (user) => user.id === data.updatedBy,
                            )?.name.full
                          }
                        />
                      ) : (
                        <ProfileName
                          user={releaseParticipantsUserData?.find(
                            (user) => user.id === data.updatedBy,
                          )}
                          variant='xsmall'
                        />
                      )}
                    </div>
                  </td>
                  {editable && (
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          color='primary'
                          variant='solid'
                          aria-label={t('common.edit')}
                          size='sm'
                          onClick={() => editRow(index)}
                          disabled={rowEditing !== undefined}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </IconButton>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default TranslationsTable;
