import React from 'react';
import Subtitle from '../subtitle/subtitle';
import { css } from 'aphrodite';
import basicReleaseDetailsStyles from './basic-release-details.styles';
import { Skeleton, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export type BasicReleaseDetailsProps = {
  isLoading: boolean;
  projectName?: string;
  versionName?: string;
};

const BasicReleaseDetails = ({
  isLoading,
  projectName,
  versionName,
}: BasicReleaseDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className={css(basicReleaseDetailsStyles.container)}>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
        <Subtitle>{t('basic-release-details.project')}</Subtitle>

        <div className={css(basicReleaseDetailsStyles.dataText)}>
          <Skeleton variant='inline' loading={isLoading}>
            <Typography noWrap>{projectName}</Typography>
          </Skeleton>
        </div>
      </div>
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
        <Subtitle>{t('basic-release-details.version')}</Subtitle>
        <div className={css(basicReleaseDetailsStyles.dataText)}>
          <Skeleton variant='inline' loading={isLoading}>
            <Typography noWrap>{versionName}</Typography>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default BasicReleaseDetails;
