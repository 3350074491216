import React from 'react';
import Card from '../card/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../../styles/theme';
import { Link } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export type HelpItem = {
  title: string;
  text: string;
  link?: string;
  onLinkClick?: () => void;
};

export type HelpBoxProps = {
  items: HelpItem[];
  collapsible?: boolean;
  onCollapse?: (collapsed: boolean) => void;
};

const HelpBox = ({ items, collapsible = false, onCollapse }: HelpBoxProps) => {
  const [collapsed, setCollapsed] = React.useState(collapsible);

  const { t } = useTranslation();

  const handleCollapse = () => {
    const newValue = !collapsed;
    setCollapsed(newValue);
    if (onCollapse) {
      onCollapse(newValue);
    }
  };

  return (
    <Card
      sx={{
        width: collapsible
          ? collapsed
            ? 'calc(calc(calc(100% - 128px) * 0.25) - 48px)'
            : 'calc(calc(calc(100% - 128px) * 0.25) - 64px)'
          : undefined,
        position: collapsible ? 'absolute' : 'relative',
        padding: collapsible && collapsed ? '16px' : '24px',
        maxHeight: collapsible ? (collapsed ? '58px' : '2000px') : undefined,
        overflow: 'hidden',
        zIndex: 2,
        transition: `all 0.5s ease`,
        height: 'fit-content'
      }}
      data-testid='help-box'
    >
      <>
        <div
          style={{
            margin: '8px 0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            color={theme.colour.primary}
            size={collapsed ? '3x' : '4x'}
            style={{ transition: `all 0.5s ease` }}
          />
          {collapsible && <h3 style={{ margin: 0 }}>{t('common.help')}</h3>}
          {collapsible && (
            <Link component='button' onClick={handleCollapse}>
              <FontAwesomeIcon
                icon={collapsed ? faAngleDown : faAngleUp}
                size='xl'
              />
            </Link>
          )}
        </div>
        <div
          style={{
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {items.map((item, index) => (
            <div key={`${item.title}-${index}`}>
              <div style={{ fontWeight: 'bold' }}>{t(item.title)}</div>
              <div>{t(item.text)}</div>
              {item.link && (
                <div>
                  <Link component='a' onClick={item.onLinkClick} sx={{ fontSize: '14px' }}>
                    {t(item.link)}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    </Card>
  );
};

export default HelpBox;
