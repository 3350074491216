import React from 'react';
import { useCookies } from 'react-cookie';
import { Language } from '../types/misc';

interface CookieContextInterface {
  nonEssentialConsent: boolean | undefined;
  grantNonEssentialCookieConsent: () => void;
  revokeNonEssentialCookieConsent: () => void;
  langCookie: Language;
  setLangCookie: (lang: Language) => void;
  openCookiePreferencesModal: () => void;
  closeCookiePreferencesModal: () => void;
  isCookiePreferencesModalOpen: boolean;
  setHighlightUntranslatedPreference: (preference: boolean) => void;
  highlightUntranslatedPreference: boolean | undefined;
}

export enum OTCookie {
  NonEssentialConsent = 'ot-ne-consent',
  Lang = 'ot-lang',
  HighlightUntranslated = 'ot-highlight-untranslated'
}

// TODO: Add non-essential cookies as they are developed
const nonEssentialCookies: OTCookie[] = [OTCookie.HighlightUntranslated];

const getExpiryDate = (): Date => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

const CookieContext = React.createContext<CookieContextInterface | undefined>(
  undefined,
);

export const useCookieContext = () => {
  const context = React.useContext(CookieContext);

  if (!context) {
    throw new Error(
      'useCookieContext must be used within a CookieContextProvider',
    );
  }

  return context;
};

export const CookieContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(
    Object.values(OTCookie),
  );

  const [isCookiePreferencesModalOpen, setIsCookiePreferencesModalOpen] =
    React.useState(false);

  const openCookiePreferencesModal = () => {
    setIsCookiePreferencesModalOpen(true);
  };

  const closeCookiePreferencesModal = () => {
    setIsCookiePreferencesModalOpen(false);
  };

  const [nonEssentialConsent, setNonEssentialConsent] = React.useState<
    boolean | undefined
  >(undefined);
  const [lang, setLang] = React.useState<Language>('en');
  const [highlightUntranslated, setHighlightUntranslated] = React.useState(false);

  const grantNonEssentialCookieConsent = () => {
    setNonEssentialConsent(true);
    setCookie(OTCookie.NonEssentialConsent, true, {
      expires: getExpiryDate(),
      path: '/',
    });
  };

  const revokeNonEssentialCookieConsent = () => {
    setNonEssentialConsent(false);
    setCookie(OTCookie.NonEssentialConsent, false, {
      expires: getExpiryDate(),
      path: '/',
    });
    nonEssentialCookies.forEach((cookie) => removeCookie(cookie, { path: '/', domain: window.location.hostname }));
  };

  const setLangCookie = (lang: Language) => {
    setCookie(OTCookie.Lang, lang, { expires: getExpiryDate(), path: '/' });
  };

  const setHighlightUntranslatedPreference = (preference: boolean) => {
    setHighlightUntranslated(preference);
    if (nonEssentialConsent) {
      setCookie(OTCookie.HighlightUntranslated, preference, { expires: getExpiryDate(), path: '/' });
    }
  }

  React.useEffect(() => {
    setNonEssentialConsent(cookies[OTCookie.NonEssentialConsent]);
    if (cookies[OTCookie.NonEssentialConsent] === undefined) {
      openCookiePreferencesModal();
    }
    setLang(cookies[OTCookie.Lang]);
    setHighlightUntranslated(cookies[OTCookie.HighlightUntranslated] ?? false);
  }, [cookies]);

  return (
    <CookieContext.Provider
      value={{
        nonEssentialConsent,
        grantNonEssentialCookieConsent,
        revokeNonEssentialCookieConsent,
        langCookie: lang,
        setLangCookie,
        openCookiePreferencesModal,
        closeCookiePreferencesModal,
        isCookiePreferencesModalOpen,
        highlightUntranslatedPreference: highlightUntranslated,
        setHighlightUntranslatedPreference
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};
