import { Link } from '@mui/joy';
import React from 'react';
import axios, { AxiosError } from 'axios';
import Message from '../message/message';
import { useTranslation } from 'react-i18next';

export type ErrorMessageProps = {
  isError: boolean;
  error?: unknown;
  retry?: () => void;
  children?: React.ReactNode;
};

const ErrorMessage = ({
  isError,
  error,
  retry,
  children,
}: ErrorMessageProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isError && (
        <Message variant='danger' title={t('errors.general')}>
          <div>
            <div>
              {error instanceof AxiosError
                ? (error.response?.data?.error?.message ?? error?.message)
                : t('errors.unknown-error')}
            </div>
            <div>{children}</div>
            {retry ? (
              <Link onClick={retry} color='danger' sx={{ fontSize: '14px' }}>
                {t('errors.try-again-click')}
              </Link>
            ) : (
              <>{t('errors.try-again')}</>
            )}
          </div>
          {axios.isAxiosError(error) && (
            <div style={{ fontSize: '12px' }}>
              <div>
                {t('errors.correlation')}{' '}
                {error.config?.headers['correlation-id']}
              </div>
              <div>{t('errors.code')}{' '}{error.response?.data?.error?.code}</div>
            </div>
          )}
        </Message>
      )}
    </>
  );
};

export default ErrorMessage;
